<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <notifications :pauseOnHover="true" />
        <div class="forground-container">
            <div class="forground">
                <bannerItems :items="selectedBannerItemsData"/>
            </div>
        </div>
        <div class="forground-container">
            <div class="forground">
                <div class="mb-3 flex justify-between">
                    <mdText :text="'Banner slider items'" />
                    <div class="flex justify-end gap-5">
                        <select v-model="selectedFilter" @change="getFeaturedItemsData()"
                            class="w-[200px] h-10 px-4 rounded-md text-sm bg-fg-light dark:bg-fg-dark border border-[#dedede] dark:border-[#424242]"
                            name="" id="">
                            <option selected value="all">Filter (All)</option>
                            <option value="selected">Selected</option>
                            <option value="not-selected">Not Selected</option>
                        </select>
                    </div>
                </div>
                <div>
                    <div v-for="(item, index) in items" :key="index"
                        class="py-3 flex justify-between items-start gap-5 border-b border-b-[#e9e9e9] dark:border-b-[#525252]">
                        <div class="w-full flex gap-5">
                            <div
                                class="w-[100px] h-[80px] flex-shrink-0 flex justify-center items-center bg-fg-light dark:bg-fg-dark">
                                <img class="max-w-full max-h-full" :src="item.img_path_1" alt="">
                            </div>
                            <p class="font-bold">{{ item.name }}</p>
                        </div>
                        <div class="w-24 flex-shrink-0">
                            <button @click="itemOperationTrigger(index)"
                                class="w-full py-2 rounded-md font-bold text-white bg-primary">{{ item.status === true ?
                                    'Remove' : 'Add' }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import Loading from '@/components/common/loading-overlay-com.vue';
import mdText from '@/components/common/titles/md-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import bannerItems from "@/components/items/banner-items-com.vue"
import { getFeaturedItems, addItemToFeatured, removeItemFromFeatured, getBannerSliderItems } from '@/services/api/items';
// import { swal } from '@/utils/helper';
import Swal from 'sweetalert2';
import { CONSTANTS } from '@/utils/constants';

const { notify } = useNotification();

const selectedType = ref('banner_slider_items')
const selectedFilter = ref('all');
const isLoading = ref(false);
const items = ref([]);
const selectedBannerItemsData = ref([]);

onMounted(() => {
    getFeaturedItemsData();
});

const selectedBannerItems = async () => {
    isLoading.value = true;
    try {
        const response = await getBannerSliderItems();
        selectedBannerItemsData.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const getFeaturedItemsData = async () => {
    selectedBannerItems();
    isLoading.value = true;
    try {
        const response = await getFeaturedItems(selectedType.value, selectedFilter.value);
        items.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const itemOperationTrigger = async (index) => {
    let themeData = localStorage.getItem('themeData');
    if (items.value[index].status === false) {

        Swal.fire({
            title: "Confirmation",
            text: `Are you sure you want to add this item into 'Banner items'?`,
            showCancelButton: true,
            confirmButtonText: "Save",
            cancelButtonColor: 'red',
            confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
            color: themeData === 'dark' ? 'white' : 'black',
            background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
        }).then(async (result) => {
            if (result.isConfirmed) {
                isLoading.value = true;
                const postData = {
                    itemId: items.value[index].item_id,
                    type: 'banner_slider_items',
                };

                try {
                    const response = await addItemToFeatured(postData);
                    getFeaturedItemsData();
                    notify({
                        type: "success",
                        title: response.data.title,
                        text: response.data.message,
                    });
                } catch (error) {
                    notify({
                        type: "error",
                        title: error.response.data.title,
                        text: error.response.data.message,
                    });
                }
                isLoading.value = false;
            }
        });
    } else if (items.value[index].status === true) {
        Swal.fire({
            title: "Confirmation",
            text: `Are you sure you want to remove this item from 'Banner items'?`,
            showCancelButton: true,
            confirmButtonText: "Save",
            cancelButtonColor: 'red',
            confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
            color: themeData === 'dark' ? 'white' : 'black',
            background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
        }).then(async (result) => {
            if (result.isConfirmed) {
                isLoading.value = true;
                const postData = {
                    id: items.value[index].id,
                    type: 'banner_slider_items',
                };

                try {
                    const response = await removeItemFromFeatured(postData);
                    getFeaturedItemsData();
                    notify({
                        type: "success",
                        title: response.data.title,
                        text: response.data.message,
                    });
                } catch (error) {
                    notify({
                        type: "error",
                        title: error.response.data.title,
                        text: error.response.data.message,
                    });
                }
                isLoading.value = false;
            }
        });
    } else {
        notify({
            type: "error",
            title: "Error!",
            text: "Unexpected error. Pleae refresh the page and try again",
        });
    }
};
</script>