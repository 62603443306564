import axios from "axios";
import { CONSTANTS } from '@/utils/constants';
import store from '@/store/index';

// user login
export const loginUser = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/staff/staff-login`, data);
    return response;
};

// get users
export const fetchUsersData = async (type) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/users/get-all-users/${type}`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get newsletter subscribers
export const fetchNewsletterSubscribers = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/users/get-newsletter-subscribers`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};