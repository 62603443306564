<template>
    <div @click="itemInfoPopupClose()"
        class="flex justify-center items-center fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm bg-[#00000038] z-10">
        <loading :loading="isLoading" />
        <div @click.stop
            class="max-w-[700px] max-h-[80vh] w-full p-5 flex flex-col justify-start rounded-md bg-bg-light dark:bg-bg-dark">
            <div class="h-[120px]  mb-5">
                <div class="flex justify-end">
                    <button @click="itemInfoPopupClose()">
                        <Icon class="w-5 h-5" icon="ic:baseline-close" />
                    </button>
                </div>
                <p class="mb-1 text-center text-lg font-bold">{{ props.orderData.type }}</p>
                <p class="mb-1 text-center text-[12px]">{{ props.orderData.id }}</p>
                <p class="text-center text-[12px] font-bold"
                    :class="props.orderData.status === 'pending' ? 'text-[#FFA500]' : props.orderData.status === 'accepted' ? 'text-[#1E90FF]' : props.orderData.status === 'finished' ? 'text-[#32CD32]' : 'text-[#FF0000]'">
                    {{ props.orderData.status.toUpperCase() }}</p>
            </div>
            <div class="max-h-full overflow-y-auto">
                <div class="mb-10">
                    <mdIconText :icon="'material-symbols:account-circle'" :text="'User Data'" />
                    <div class="w-full h-[1px] mb-3 bg-fg-light dark:bg-fg-dark"></div>
                    <p>User ID: {{ props.orderData.user_id }}</p>
                    <p>User Name: {{ props.orderData.first_name + ' ' + props.orderData.last_name }}</p>
                </div>
                <div class="mb-10">
                    <mdIconText :icon="'mdi:tshirt-crew'" :text="'Item Data'" />
                    <div class="w-full h-[1px] mb-3 bg-fg-light dark:bg-fg-dark"></div>
                    <p>Item ID: {{ props.orderData.item_id }}</p>
                    <p>Item Name: {{ props.orderData.item_name }}</p>
                    <p>Brand: {{ props.orderData.brand === null ? '---' : props.orderData.brand }}</p>
                    <p>Model: {{ props.orderData.model === null ? '---' : props.orderData.model }}</p>
                    <p>Item Size: {{ props.orderData.item_size === null ? '---' : props.orderData.item_size }}</p>
                    <p>Item Quantity: {{ props.orderData.quantity === null ? '---' : props.orderData.quantity }}</p>
                </div>
                <div class="mb-10">
                    <mdIconText :icon="'material-symbols:order-play-rounded'" :text="'Order Data'" />
                    <div class="w-full h-[1px] mb-3 bg-fg-light dark:bg-fg-dark"></div>
                    <p>Finishing: {{ props.orderData.finishing }}</p>
                    <p>Ordered Date: {{ props.orderData.ordered_date_time }}</p>
                    <p>Message: {{ props.orderData.message }}</p>
                    <div class="my-5 flex gap-5">
                        <div>
                            <p>Item Image</p>
                            <div class="w-[150px] h-[100px] flex justify-center items-center relative bg-fg-light dark:bg-fg-dark">
                                <a v-if="props.orderData.shoe_img !== null" :href="props.orderData.shoe_img" class="grid place-items-center absolute left-0 top-0 right-0 bottom-0 duration-300 hover:bg-[#00000076]">
                                    <Icon class="w-10 h-10 text-white" icon="material-symbols:download-rounded" />
                                </a>
                                <Icon v-if="props.orderData.shoe_img === null" class="w-10 h-10"
                                    icon="material-symbols:imagesmode-rounded" />
                                <img v-else class="w-full h-full object-cover" :src="props.orderData.shoe_img" alt="">
                            </div>
                        </div>
                        <div>
                            <p>Artwork Image</p>
                            <div class="w-[150px] h-[100px] flex justify-center items-center relative bg-fg-light dark:bg-fg-dark">
                                <a v-if="props.orderData.artwork_img !== null" :href="props.orderData.artwork_img" class="grid place-items-center absolute left-0 top-0 right-0 bottom-0 duration-300 hover:bg-[#00000076]">
                                    <Icon class="w-10 h-10 text-white" icon="material-symbols:download-rounded" />
                                </a>
                                <Icon v-if="props.orderData.artwork_img === null" class="w-10 h-10"
                                    icon="material-symbols:imagesmode-rounded" />
                                <img v-else class="w-full h-full object-cover" :src="props.orderData.artwork_img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="props.orderData.status !== 'cancelled' && props.orderData.status !== 'finished'" class="pt-5">
                <div v-if="props.orderData.status === 'accepted'" class="mb-5 grid grid-cols-2 gap-3">
                    <div>
                        <input v-model="price" class="w-full h-10 p-3 rounded-md bg-transparent border"
                            :class="errors.price === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'"
                            type="text" placeholder="Price">
                        <p v-if="errors.price" class="text-sm text-red-600">{{ errors.price }}</p>
                    </div>
                    <div>
                        <input v-model="discount" class="w-full h-10 p-3 rounded-md bg-transparent border"
                            :class="errors.discount === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'"
                            type="text" placeholder="Discount">
                        <p v-if="errors.discount" class="text-sm text-red-600">{{ errors.discount }}</p>
                    </div>
                </div>
                <div class="flex justify-center gap-3">
                    <button @click="orderStatusChange()" class="py-4 px-6 rounded-md"
                        :class="props.orderData.status === 'pending' ? 'text-[#1E90FF] bg-[#1e8fff46]' : 'text-[#32CD32] bg-[#32cd3246]'">
                        {{ props.orderData.status === 'pending' ? 'Accept' : 'Finish' }} Order</button>
                    <button v-if="props.orderData.status === 'pending'" @click="orderStatusCancel()"
                        class="py-4 px-6 rounded-md text-[#FF0000] bg-[#ff000046]">Cancel Order</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, defineEmits } from 'vue';
import { Icon } from '@iconify/vue';
import { swal } from '@/utils/helper';
import loading from '@/components/common/loading-overlay-com.vue';
import mdIconText from '@/components/common/titles/md-icon-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import { changeStatus } from '@/services/api/customize';
import Swal from 'sweetalert2';
import { CONSTANTS } from '@/utils/constants';

const { notify } = useNotification();

const props = defineProps({
    orderData: {
        type: Object,
    },
});

const isLoading = ref(false);
const price = ref('');
const discount = ref('');

const errors = ref({
    price: '',
    discount: ''
});

const orderStatusChange = async () => {
    const status = props.orderData.status === 'pending' ? 'accepted' : props.orderData.status === 'accepted' ? 'finished' : props.orderData.status;
    let themeData = localStorage.getItem('themeData');

    const postData = {
        id: props.orderData.id,
        status: status,
        price: price.value,
        discount: discount.value,
        note: null,
    };

    if (status === 'finished') {
        if (price.value === '') {
            errors.value.price = 'Price is required!';
        } else {
            errors.value.price = '';
        }

        if (errors.value.price === '') {

            Swal.fire({
                title: "Confirmation",
                text: `Are you sure you want to change the status of this order item into finish?`,
                showCancelButton: true,
                confirmButtonText: "Save",
                cancelButtonColor: 'red',
                confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
                color: themeData === 'dark' ? 'white' : 'black',
                background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    isLoading.value = true;

                    try {
                        const response = await changeStatus(postData);
                        itemInfoPopupClose(response.data);
                    } catch (error) {
                        notify({
                            type: "error",
                            title: error.response.data.title,
                            text: error.response.data.message,
                        });
                    }
                    isLoading.value = false;
                }
            });
        }
    } else {
        Swal.fire({
            title: "Confirmation",
            text: `Are you sure you want to change the status of this order item into accept?`,
            showCancelButton: true,
            confirmButtonText: "Save",
            cancelButtonColor: 'red',
            confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
            color: themeData === 'dark' ? 'white' : 'black',
            background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
        }).then(async (result) => {
            if (result.isConfirmed) {
                isLoading.value = true;

                try {
                    const response = await changeStatus(postData);
                    itemInfoPopupClose(response.data);
                } catch (error) {
                    notify({
                        type: "error",
                        title: error.response.data.title,
                        text: error.response.data.message,
                    });
                }
                isLoading.value = false;
            }
        });
    }
};

const orderStatusCancel = async () => {
    Swal.fire({
        title: "Confirmation",
        text: `Please type the reason why this customize order should be cancelled?`,
        input: "text",
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonColor: 'red',
        confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
        color: themeData === 'dark' ? 'white' : 'black',
        background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
    }).then(async (result) => {
        if (result.isConfirmed) {
            isLoading.value = true;

            try {
                const postData = {
                    id: props.orderData.id,
                    status: 'cancelled',
                    price: null,
                    discount: null,
                    note: result.value
                };

                const response = await changeStatus(postData);
                itemInfoPopupClose(response.data);
            } catch (error) {
                notify({
                    type: "error",
                    title: error.response.data.title,
                    text: error.response.data.message,
                });
            }
            isLoading.value = false;
        }
    });
};

const emits = defineEmits(['newsletterStatusChangePopupClose', 'orderStatusChange']);

const itemInfoPopupClose = (response) => {
    emits('itemInfoPopupClose', response);
};
</script>