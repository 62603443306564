import axios from "axios";
import { CONSTANTS } from '@/utils/constants';
import store from '@/store/index';

// add staff user
export const addStaffUser = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/staff/add-staff-user`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// staff user login
export const staffUserLogin = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/staff/staff-login`, data);
    return response;
};

// get logged staff userdata
export const getLoggedStaffUserdata = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/staff/get-staff-userdata`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get all staff userdata
export const getAllStaffUserdata = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/staff/get-all-staff-users`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get all staff userdata
export const deleteStaffUser = async (staffId) => {
    const response = await axios.delete(`${CONSTANTS.BASE_URL}/staff/deactivate-staff-user/${staffId}`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get all staff userdata
export const fetchUserRole = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/staff/get-user-role`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// password change
export const changePassword = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/staff/change-password`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};