<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <notifications />
        <transition name="fade">
            <OrderInfoPopup v-if="orderDetailsPopupIsVisible" :orderData="selectedOrder" @itemInfoPopupClose="itemInfoPopupClose"/>
        </transition>
        <div class="forground-container">
            <div class="forground">
                <div class="flex justify-between">
                    <mdText :text="'Reviews'" />
                    <div class="px-[5px] md:px-[15px] flex justify-end gap-3">
                        <!-- <select v-model="selectedType" @change="getCustomizedOrders()"
                            class="w-40 h-8 px-2 rounded-md outline-none text-sm bg-fg-light dark:bg-fg-dark" name=""
                            id="selectedType">
                            <option selected value="customize">Customize</option>
                            <option value="restore">Restore</option>
                            <option value="pre-owned">Pre-Owned</option>
                        </select> -->
                        <select v-model="selectedStatus" @change="getOrders()"
                            class="w-40 h-8 px-2 rounded-md outline-none text-sm bg-fg-light dark:bg-fg-dark" name=""
                            id="selectedFilter">
                            <option selected value="all">Type - All</option>
                            <option value="pending">Pending</option>
                            <option value="accepted">Accepted</option>
                            <option value="finished">Finished</option>
                            <option value="cancelled">Cancelled</option>
                        </select>
                    </div>
                </div>
                <div>
                    <p v-if="orders.length === 0 && isLoading === false" class="text-center py-20">No Items Found</p>
                    <table v-else class="min-w-[700px] w-full">
                        <tr>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Order ID</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Type</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">User Name</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Ordered Date</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Payment Type</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Price</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Status</th>
                        </tr>
                        <tr v-for="(order, index) in orders" :key="index" @click="orderDetailsPopupOpen(index)"
                            class="border-b border-gray-200 dark:border-gray-600 cursor-pointer">
                            <td class="px-2 py-3 text-sm text-center">{{ order.id }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ order.type }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ order.first_name + ' ' + order.last_name }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ order.ordered_date_time.date_string }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ order.payment_type }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ order.total.toLocaleString() }}</td>
                            <td class="w-[100px] px-2 py-3 text-sm text-center"
                                :class="order.status === 'pending' ? 'text-[#FFA500] bg-[#ffa60046]' : order.status === 'accepted' ? 'text-[#1E90FF] bg-[#1e8fff46]' : order.status === 'delivered' ? 'text-[#2b902b] bg-[#05740546]' : order.status === 'completed' ? 'text-[#32CD32] bg-[#32cd3246]' : 'text-[#FF0000] bg-[#ff000046]'">
                                {{ order.status }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import mdText from '@/components/common/titles/md-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import { getAllOrders } from '@/services/api/orders';
import OrderInfoPopup from '@/components/orders/item-info-popup-com.vue';

const { notify } = useNotification();

const isLoading = ref(false);
// const selectedType = ref('customize');
const selectedStatus = ref('all');
const orders = ref([]);
const selectedOrder = ref(null);
const orderDetailsPopupIsVisible = ref(false);

onMounted(() => {
    getOrders();
});

const getOrders = async () => {
    isLoading.value = true;
    try {
        const response = await getAllOrders(selectedStatus.value);
        orders.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const orderDetailsPopupOpen = (index) => {
    selectedOrder.value = orders.value[index];
    orderDetailsPopupIsVisible.value = true;
};

const itemInfoPopupClose = (val) => {
    selectedOrder.value = null;
    orderDetailsPopupIsVisible.value = false;

    if(val) {
        getOrders();
        notify({
            type: "success",
            title: val.title,
            text: val.message,
        });
    }
}

</script>