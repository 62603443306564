<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <div class="forground-container flex flex-col gap-5">
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
                <div v-for="(item, index) in ordersummarize" :key="index" class="forground py-5 flex items-center gap-2">
                    <div class="w-16 h-16">
                        <Icon class="w-16 h-16" :style="{ color: item.bg }" icon="material-symbols:order-play-rounded" />
                    </div>
                    <div>
                        <p class="text-sm font-bold line-clamp-1">{{ item.name }}</p>
                        <p class="text-sm">{{ item.count }}</p>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-5">
                <div class="forground">
                    <mdText :text="'Most Viewed Items'" />
                    <div class="flex flex-col lg:flex-row justify-center items-center gap-3">
                        <Pie v-if="pieChartData.labels.length !== 0"
                            class="max-w-[300px] max-h-[300px] mt-5 lg:mt-0 order-2 lg:order-1" :options="pieChartOptions"
                            :data="pieChartData" />
                        <div class="flex flex-row flex-wrap lg:flex-col justify-center gap-1 lg:gap-3 order-1 lg:order-2">
                            <div v-for="(item, index) in pieChartData.labels" :key="index" @click="toggleData(index)"
                                class="max-w-[100px] lg:max-w-[200px] w-full flex items-center gap-1">
                                <div class="w-6 h-4 flex-shrink-0"
                                    :class="index === 0 ? 'bg-[#FFD700]' : index === 1 ? 'bg-[#C0C0C0]' : index === 2 ? 'bg-[#CD7F32]' : index === 3 ? 'bg-[#1E90FF]' : 'bg-[#32CD32]'">
                                </div>
                                <p class="text-[12px] line-clamp-1">{{ item }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="forground">
                    <mdText :text="'Ordered Item Type Difference'" />
                    <div class="h-[90%] grid place-items-center">
                        <Line v-if="lineChartData.labels.length !== 0" :options="lineChartOptions" :data="lineChartData" />
                    </div>
                </div>
            </div>
            <div class="forground">
                <mdText :text="'Active Orders'" />
                <div class="o overflow-y-auto">
                    <table class="min-w-[700px] w-full">
                        <tr>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Order ID</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Type</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">User Name</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Ordered Date</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Payment Type</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Price</th>
                        </tr>
                        <tr v-for="(order, index) in activeOrders" :key="index"
                            class="border-b border-gray-200 dark:border-gray-600">
                            <td class="px-2 py-3 text-sm text-center">{{ order.id }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ order.type }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ order.first_name + ' ' + order.last_name }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ order.ordered_date_time.date_string }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ order.payment_type }}</td>
                            <td class="px-2 py-3 text-sm text-right">{{ order.total.toLocaleString() }}</td>
                        </tr>
                    </table>
                    <p v-if="isLoading === false && activeOrders.length === 0" class="py-10 text-center">No Active Order(s)</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import { Icon } from '@iconify/vue';
import mdText from '@/components/common/titles/md-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import { Pie, Line } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { getMostViewedItems, getOrdersSummarizeCount, getActiveOrders, getLatestMonthsOrders } from '@/services/api/analytics'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);
const { notify } = useNotification();

const isLoading = ref(false);
const ordersummarize = ref([]);
const pieChartData = ref({
    labels: [],
    datasets: [{
        data: [],
        backgroundColor: ['#FFD700', '#C0C0C0', '#CD7F32', '#1E90FF', '#32CD32']
    }]
});
const lineChartData = ref({
    labels: [],
    datasets: [
        {
            label: ['Normal Orders'],
            backgroundColor: '#4bc0c033',
            borderColor: '#4bc0c0',
            data: [],
            tension: 0.4
        },
        {
            label: ['Drop Orders'],
            backgroundColor: '#ffd90044',
            borderColor: '#FFD700',
            data: [],
            tension: 0.4
        },
    ],
});
const pieChartOptions = ref({
    plugins: {
        legend: {
            display: false
        }
    }
});
// const lineChartOptions = ref({
//     maintainAspectRatio: false
// });
const activeOrders = ref([]);

onMounted(() => {
    getMostViewedItemsData();
    getOrdersSummarizeCountData();
    getActiveOrdersData();
    getLatestMonthsOrdersData();
})

const getMostViewedItemsData = async () => {
    isLoading.value = true;
    try {
        const response = await getMostViewedItems();
        const items = response.data;

        pieChartData.value.labels = items.map(item => item.name);
        pieChartData.value.datasets[0].data = items.map(item => item.views);
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const getOrdersSummarizeCountData = async () => {
    isLoading.value = true;
    try {
        const response = await getOrdersSummarizeCount();
        ordersummarize.value = [
            { name: 'Total Orders', bg: '#1E90FF', count: response.data[0].total_count },
            { name: 'Delivered Orders', bg: '#2b902b', count: response.data[0].delivered_count === null ? 0 : response.data[0].delivered_count },
            { name: 'Pending Orders', bg: '#FFA500', count: response.data[0].pending_count === null ? 0 : response.data[0].pending_count },
            { name: 'Cancelled Orders', bg: '#FF0000', count: response.data[0].cancelled_count === null ? 0 : response.data[0].cancelled_count },
        ]
    } catch (error) {

    }
    isLoading.value = false;
};

const getActiveOrdersData = async () => {
    isLoading.value = true;
    try {
        const response = await getActiveOrders();
        activeOrders.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const getLatestMonthsOrdersData = async () => {
    isLoading.value = true;
    try {
        const response = await getLatestMonthsOrders();
        lineChartData.value.labels = response.data.months;
        lineChartData.value.datasets[0].data = response.data.nonDropOrders;
        lineChartData.value.datasets[1].data = response.data.dropOrders;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

</script>