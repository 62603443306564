<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <notifications />
        <StockAddPopup v-if="isStocksAddingPopupVisible" @addStocksPopupClose="addStocksPopupClose" />
        <div class="forground-container">
            <div class="forground">
                <div class="mb-5 flex justify-between items-center gap-5">
                    <mdText :text="'Stock History'" />
                    <BtnText class="w-[220px]" :icon="'material-symbols:add-circle-outline-rounded'"
                        :buttonText="'Add Stock'" @clickEvent="addStocksPopupOpen()" />
                </div>
                <div>
                    <div class="mb-4 flex items-center gap-5">
                        <div class="flex items-center gap-2">
                            <div class="w-4 h-4 bg-[#1e8fff46]"></div>
                            <span class="text-sm">Added</span>
                        </div>
                        <div class="flex items-center gap-2">
                            <div class="w-4 h-4 bg-[#05740546]"></div>
                            <span class="text-sm">Sold</span>
                        </div>
                        <div class="flex items-center gap-2">
                            <div class="w-4 h-4 bg-[#ff000046]"></div>
                            <span class="text-sm">Removed</span>
                        </div>
                    </div>
                    <table class="min-w-[700px] w-full">
                        <tr>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">ID</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Supplier Name</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Item Name</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Date</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Stocks</th>
                        </tr>
                        <tr v-for="(item, index) in stocksHistory" :key="index"
                            class="border-b border-gray-200 dark:border-gray-600" :class="item.status === 'ad' ? 'bg-[#1e8fff46]' : item.status === 'sl' ? 'bg-[#05740546]' : 'bg-[#ff000046]'">
                            <td class="px-2 py-3 text-sm text-center">{{ item.id }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ item.supplier_name }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ item.item_name }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ item.date_time }}</td>
                            <td class="max-w-[350px] px-2 py-3 text-sm text-center">
                                <div class="flex flex-wrap gap-3">
                                    <div v-for="(item, index) in item.sizes_stocks" :key="index"
                                        class="p-3 rounded-md flex items-center bg-fg-light dark:bg-fg-dark relative">
                                        <div class="w-7 h-7 border flex justify-center items-center rounded-full">
                                            <p class="text-[12px]">{{ item.size }}</p>
                                        </div>
                                        <p class="mx-2">-</p>
                                        <p>{{ item.stock }}</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <p v-if="isLoading === false && stocksHistory.length === 0" class="py-10 text-center">No Stock History</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import BtnText from '@/components/common/buttons/btn-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import { getStockHistory } from '@/services/api/suppliers';
import StockAddPopup from '@/components/supply/add-stock-popup';
import mdText from '@/components/common/titles/md-text.vue';

const { notify } = useNotification();

const isLoading = ref(false);
const stocksHistory = ref([]);
const isStocksAddingPopupVisible = ref(false);

onMounted(() => {
    loadStockHistory();
});

const loadStockHistory = async () => {
    isLoading.value = true;
    try {
        const response = await getStockHistory();
        stocksHistory.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const addStocksPopupOpen = (val) => {
    isStocksAddingPopupVisible.value = true;
};

const addStocksPopupClose = (val) => {
    isStocksAddingPopupVisible.value = false;

    if (val) {
        loadStockHistory();
        notify({
            type: "success",
            title: val.title,
            text: val.message,
        });
    }
};


</script>