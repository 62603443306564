<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <notifications />
        <div class="forground-container">
            <div class="forground">
                <mdText :text="'Reviews'" />
                <div class="px-[5px] md:px-[15px] flex justify-end">
                    <button @click="getUserReviews()"
                        class="w-8 h-8 mr-3 rounded-md flex justify-center items-center bg-fg-light dark:bg-fg-dark">
                        <Icon class="w-4 h-4" icon="material-symbols:directory-sync" />
                    </button>
                    <select v-model="selectedType" @change="getUserReviews()"
                        class="w-40 h-8 px-2 rounded-md outline-none text-sm bg-fg-light dark:bg-fg-dark" name=""
                        id="selectedType">
                        <option selected value="all">Type - All</option>
                        <option value="1">Type - Visible</option>
                        <option value="0">Type - Hidden</option>
                    </select>
                </div>
                <div class="mt-5 pb-10 px-[5px] md:px-[15px]">
                    <div v-for="(item, index) in reviews" :key="index"
                        class="p-5 mb-3 rounded-md flex flex-col sm:flex-row bg-fg-light dark:bg-fg-dark">
                        <div class="w-full" :class="item.is_visible == 1 ? 'opacity-100' : 'opacity-40'">
                            <div class="mb-5 flex items-center">
                                <div
                                    class="w-14 h-14 mr-3 rounded-full border-2 border-primary flex justify-center items-center overflow-hidden relative">
                                    <Icon v-if="item.img_path === null" class="w-8 h-8 text-primary"
                                        icon="material-symbols:person-2-rounded" />
                                    <img v-else class="w-full h-full object-cover" :src="item.img_path" alt="">
                                </div>
                                <div>
                                    <p class="text-sm font-bold">{{ item.first_name + ' ' + item.last_name }}</p>
                                    <div class="mt-1 flex gap-1">
                                        <Icon class="w-5 h-5 text-[#ffd000]" icon="material-symbols:kid-star" />
                                        <Icon class="w-5 h-5"
                                            :class="item.rating >= 2 ? 'text-[#ffd000]' : 'text-[#bbbbbb]'"
                                            icon="material-symbols:kid-star" />
                                        <Icon class="w-5 h-5"
                                            :class="item.rating >= 3 ? 'text-[#ffd000]' : 'text-[#bbbbbb]'"
                                            icon="material-symbols:kid-star" />
                                        <Icon class="w-5 h-5"
                                            :class="item.rating >= 4 ? 'text-[#ffd000]' : 'text-[#bbbbbb]'"
                                            icon="material-symbols:kid-star" />
                                        <Icon class="w-5 h-5"
                                            :class="item.rating >= 5 ? 'text-[#ffd000]' : 'text-[#bbbbbb]'"
                                            icon="material-symbols:kid-star" />
                                    </div>
                                </div>
                            </div>
                            <p>{{ item.message }}</p>
                        </div>
                        <div class="w-24 mt-5 sm:mt-0 flex-shrink-0">
                            <button @click="changeReviewVisibilityTrigger(index)"
                                class="w-full py-2 rounded-md font-bold text-white bg-primary">{{ item.is_visible == 1 ?
                                    'Dissable' : 'Active' }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import mdText from '@/components/common/titles/md-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
// import { swal } from '@/utils/helper';
import { Icon } from '@iconify/vue';
import { getAllReviews, changeReviewVisibility } from '@/services/api/reviews';
import Swal from 'sweetalert2';
import { CONSTANTS } from '@/utils/constants';

const { notify } = useNotification();

const isLoading = ref(false);
const selectedType = ref('all');
const reviews = ref([]);

onMounted(() => {
    getUserReviews();
});

const getUserReviews = async () => {
    isLoading.value = true;
    try {
        const response = await getAllReviews(selectedType.value);
        reviews.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const changeReviewVisibilityTrigger = async (index) => {
    const visibility = reviews.value[index].is_visible == 1 ? 'Dissable' : 'Active';
    let themeData = localStorage.getItem('themeData');

    Swal.fire({
        title: "Confirmation",
        text: `Are you sure you want to change the review visibility to '${visibility}'`,
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonColor: 'red',
        confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
        color: themeData === 'dark' ? 'white' : 'black',
        background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
    }).then(async (result) => {
        if (result.isConfirmed) {
            isLoading.value = true;
            const postData = {
                reviewId: reviews.value[index].id,
            };

            try {
                const response = await changeReviewVisibility(postData);
                reviews.value[index].is_visible = reviews.value[index].is_visible == 1 ? 0 : 1;
                notify({
                    type: "success",
                    title: response.data.title,
                    text: response.data.message,
                });
            } catch (error) {
                notify({
                    type: "error",
                    title: error.response.data.title,
                    text: error.response.data.message,
                });
            }
            isLoading.value = false;
        }
    });
};

</script>