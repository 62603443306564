<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <notifications />
        <div class="forground-container">
            <div class="forground">
                <div class="mb-3 flex justify-between">
                    <mdText :text="'Registered users'" />
                    <div class="flex justify-end gap-5">
                        <select v-model="selectedType" @change="getRegisteredUsers()"
                            class="w-[200px] h-10 px-4 rounded-md text-sm bg-fg-light dark:bg-fg-dark border border-[#dedede] dark:border-[#424242]"
                            name="" id="">
                            <option selected value="all">Filter (All)</option>
                            <option value="active">Active</option>
                            <option value="diactivated">Diactivated</option>
                        </select>
                    </div>
                </div>
                <div v-if="isLoading === false" class="overflow-x-auto">
                    <p v-if="users.length === 0" class="my-20 text-center text-xl">No User(s) Found</p>
                    <table v-else class="min-w-[700px] w-full">
                        <tr>
                            <th class="px-2 py-4 text-left bg-bg-light dark:bg-bg-dark">User ID</th>
                            <th class="px-2 py-4 text-left bg-bg-light dark:bg-bg-dark">Email</th>
                            <th class="px-2 py-4 text-left bg-bg-light dark:bg-bg-dark">First Name</th>
                            <th class="px-2 py-4 text-left bg-bg-light dark:bg-bg-dark">Last Name</th>
                            <th class="px-2 py-4 text-left bg-bg-light dark:bg-bg-dark">Phone</th>
                            <th class="w-[100px] px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Is Verified</th>
                            <th class="w-[100px] px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Status</th>
                        </tr>
                        <tr v-for="(user, index) in users" :key="index" class="border-b border-gray-200 dark:border-gray-600">
                            <td class="px-2 py-3 text-sm">{{ user.id }}</td>
                            <td class="px-2 py-3 text-sm">{{ user.email }}</td>
                            <td class="px-2 py-3 text-sm">{{ user.first_name }}</td>
                            <td class="px-2 py-3 text-sm">{{ user.last_name }}</td>
                            <td class="px-2 py-3 text-sm" :class="user.phone_1 === null ? 'text-center' : 'text-left'">{{ user.phone_1 === null ? '-' : user.phone_1 }}</td>
                            <td class="px-2 py-3 text-sm text-center" :class="user.is_verified === 'Yes' ? 'text-green-600 bg-[#0080002e]' : 'text-red-600 bg-[#ff00002e]'">{{ user.is_verified }}</td>
                            <td class="px-2 py-3 text-sm text-center" :class="user.status === 'active' ? 'text-green-600 bg-[#0080002e]' : 'text-red-600 bg-[#ff00002e]'">{{ user.status }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import mdText from '@/components/common/titles/md-text.vue';
import { fetchUsersData } from '@/services/api/users';
import { useNotification } from "@kyvg/vue3-notification";

const { notify } = useNotification();

const isLoading = ref(true);
const selectedType = ref('all');
const users = ref([]);

onMounted(() => {
    getRegisteredUsers();
});

const getRegisteredUsers = async () => {
    isLoading.value = true;
    try {
        const response = await fetchUsersData(selectedType.value);
        users.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

</script>