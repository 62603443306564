export const CONSTANTS = {
    // BASE_URL: 'http://localhost:3000',
    BASE_URL: 'https://api.customsneakers.lk',
    CURRECNCY_TYPE: 'RM',
    COLOR_PRIMARY: '#d89b00', 
    COLOR_SECONDARY: "#825d00",
    COLOR_BG_LIGHT: "#f6f5f8",
    COLOR_FG_LIGHT: "#FFFFFF",
    COLOR_FG_DARK: "#2f3348",
    COLOR_BG_DARK: "#25293b",
}