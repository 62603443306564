import axios from "axios";
import { CONSTANTS } from '@/utils/constants';
import store from '@/store/index';

// get users orders data
export const getAllOrders = async (status) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/orders/get-all-orders/${status}`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// change customize order status
export const changeStatus = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/orders/update-status`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};