<template>
    <div @click="staffAddPopupClose()"
        class="flex justify-center items-center fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm bg-[#00000038] z-10">
        <loading :loading="isLoading" />
        <notifications />
        <div @click.stop
            class="max-w-[700px] max-h-[80vh] w-full p-5 flex flex-col justify-start rounded-md bg-bg-light dark:bg-bg-dark">
            <div class="h-[20px] mb-5">
                <div class="flex justify-end">
                    <button @click="staffAddPopupClose('test')">
                        <Icon class="w-5 h-5" icon="ic:baseline-close" />
                    </button>
                </div>
            </div>
            <div class="max-h-full overflow-y-auto">
                <labelText :id="'name'" :type="'text'" :label="'Name'" :value="name" :placeholder="'Staff member\'s name'"
                    :error="errors.name" @input-field-value-changed="nameValueChanged" />
                <labelSelect :id="'role'" :label="'Role'" :value="role" :options="roles"
                    :extra-option="{ name: 'Select an option', value: '', isVisible: true }" :error="errors.role"
                    @input-field-value-changed="roleValueChanged" />
                <labelText :id="'username'" :type="'text'" :label="'Username'" :value="username"
                    :placeholder="'Staff member\'s login username'" :error="errors.username"
                    @input-field-value-changed="usernameValueChanged" />
                <labelText :id="'password'" :type="'password'" :label="'Password'" :value="password"
                    :placeholder="'Staff member\'s login password'" :error="errors.password"
                    @input-field-value-changed="passwordValueChanged" />
                <div class="mb-5 flex justify-center">
                    <btnText @click-event="submitAddStaffMember()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, defineEmits } from 'vue';
import { Icon } from '@iconify/vue';
import loading from '@/components/common/loading-overlay-com.vue';
import mdIconText from '@/components/common/titles/md-icon-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import { changeStatus } from '@/services/api/orders';
import Swal from 'sweetalert2';
import { CONSTANTS } from '@/utils/constants';
import labelText from '@/components/common/label-input-types/label-text.vue';
import labelSelect from '@/components/common/label-input-types/label-select.vue';
import btnText from '@/components/common/buttons/btn-text.vue';
import { addStaffUser } from '@/services/api/staff';

const { notify } = useNotification();

const isLoading = ref(false);
const roles = ref([
    { id: 'manager', name: 'Manager' },
    { id: 'staff', name: 'staff' },
])
const name = ref('');
const role = ref('');
const username = ref('');
const password = ref('');
const errors = ref({
    name: '',
    role: '',
    username: '',
    password: ''
});

const nameValueChanged = (val) => {
    name.value = val;
    errors.value.name = '';
};

const roleValueChanged = (val) => {
    role.value = val;
    errors.value.role = '';
};

const usernameValueChanged = (val) => {
    username.value = val;
    errors.value.username = '';
};

const passwordValueChanged = (val) => {
    password.value = val;
    errors.value.password = '';
};

const submitAddStaffMember = async () => {
    if (name.value === '') {
        errors.value.name = 'Name is required!';
    } else {
        errors.value.name = '';
    }

    if (role.value === '') {
        errors.value.role = 'Role is required!';
    } else {
        errors.value.role = '';
    }

    if (username.value === '') {
        errors.value.username = 'Username is required!';
    } else {
        errors.value.username = '';
    }

    if (password.value === '') {
        errors.value.password = 'Password is required!';
    } else if (password.value.length < 6) {
        errors.value.password = 'Password must be more 5 characters!'
    } else {
        errors.value.password = '';
    }

    if (!errors.value.name && !errors.value.role && !errors.value.username && !errors.value.password) {
        const postData = {
            name: name.value,
            role: role.value,
            username: username.value,
            password: password.value
        };
        
        isLoading.value = true;
        try {
            const response = await addStaffUser(postData);

            name.value = '';
            role.value = '';
            username.value = '';
            password.value = '';

            staffAddPopupClose(response.data);
        } catch (error) {
            notify({
                type: "error",
                title: error.response.data.title,
                text: error.response.data.message,
            });
        }
        isLoading.value = false;
    }
};

const emits = defineEmits(['staffAddPopupClose']);

const staffAddPopupClose = (response) => {
    emits('staffAddPopupClose', response);
};
</script>