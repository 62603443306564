<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <notifications />
        <SupplierAddPopup v-if="isSupplierAddingPopupVisible" @addSupplierPopupClose="addSupplierPopupClose"/>
        <div class="forground-container">
            <div class="forground">
                <div class="mb-5 flex justify-between items-center gap-5">
                    <mdText :text="'Suppliers'" />
                    <BtnText class="w-[220px]" :icon="'material-symbols:add-circle-outline-rounded'" :buttonText="'Add Supplier'"
                        @clickEvent="addSupplierPopupOpen()" />
                </div>
                <div>
                    <table class="min-w-[700px] w-full">
                        <tr>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">ID</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Name</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Email</th>
                        </tr>
                        <tr v-for="(item, index) in suppliers" :key="index"
                            class="border-b border-gray-200 dark:border-gray-600">
                            <td class="px-2 py-3 text-sm text-center">{{ item.id }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ item.name }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ item.email }}</td>
                        </tr>
                    </table>
                    <p v-if="isLoading === false && suppliers.length === 0" class="py-10 text-center">No Supplier(s)</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import BtnText from '@/components/common/buttons/btn-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import { getAllSuppliers } from '@/services/api/suppliers';
import SupplierAddPopup from '@/components/supply/add-suppliers-popup';
import mdText from '@/components/common/titles/md-text.vue';

const { notify } = useNotification();

const isLoading = ref(false);
const suppliers = ref([]);
const isSupplierAddingPopupVisible = ref(false);

onMounted(() => {
    loadSuppliers();
});

const loadSuppliers = async () => {
    isLoading.value = true;
    try {
        const response = await getAllSuppliers();
        suppliers.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const addSupplierPopupOpen = (val) => {
    isSupplierAddingPopupVisible.value = true;
};

const addSupplierPopupClose = (val) => {
    isSupplierAddingPopupVisible.value = false;

    if(val) {
        loadSuppliers();
        notify({
            type: "success",
            title: val.title,
            text: val.message,
        });
    }
};


</script>