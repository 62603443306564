import axios from "axios";
import { CONSTANTS } from '@/utils/constants';
import store from '@/store/index';

// get reviws
export const getStockHistory = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/suppliers/get-stock-history`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// current stock
export const getCurrentStock = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/suppliers/supplier-stock`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// add new supplier
export const addNewSupplier = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/suppliers/add-new-supplier`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get reviews
export const getSearchedItems = async (key) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/suppliers/get-searched-items/${key}`);
    return response;
};

// get all suppliers
export const getAllSuppliers = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/suppliers/get-all-suppliers`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// add new stock
export const addNewStock = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/suppliers/add-stock`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// remove stock
export const removeStock = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/suppliers/remove-stock`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};