<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <notifications />
        <StockAddPopup v-if="isStocksAddingPopupVisible" @addStocksPopupClose="addStocksPopupClose" />
        <editStockPopup v-if="isEditStockPopupVisible" :stock="selectedStockToEdit.stock" :itemId="selectedStockToEdit.itemId" :supplierId="selectedStockToEdit.supplierId" @editStockPopupClose="editStocksPopupClose"/>
        <div class="forground-container">
            <div class="forground">
                <div class="mb-5 flex justify-between items-center gap-5">
                    <mdText :text="'Current Stock'" />
                    <BtnText class="w-[220px]" :icon="'material-symbols:add-circle-outline-rounded'"
                        :buttonText="'Add Stock'" @clickEvent="addStocksPopupOpen()" />
                </div>
                <div>
                    <table class="min-w-[700px] w-full">
                        <tr>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Supplier ID</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Supplier Name</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Item Name</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Stocks</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Options</th>
                        </tr>
                        <tr v-for="(item, index) in currentStock" :key="index"
                            class="border-b border-gray-200 dark:border-gray-600">
                            <td class="px-2 py-3 text-sm text-center">{{ item.supplier_id }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ item.supplier_name }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ item.item_name }}</td>
                            <td class="max-w-[350px] px-2 py-3 text-sm text-center">
                                <div class="flex flex-wrap gap-3">
                                    <div v-for="(item, index) in item.sizes_stocks" :key="index"
                                        class="p-3 rounded-md flex items-center bg-fg-light dark:bg-fg-dark relative">
                                        <div class="w-7 h-7 border flex justify-center items-center rounded-full">
                                            <p class="text-[12px]">{{ item.size }}</p>
                                        </div>
                                        <p class="mx-2">-</p>
                                        <p>{{ item.stock }}</p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-2 py-3 flex justify-center">
                                <button @click="editStocksPopupOpen(index)"
                                    class="w-8 h-8 p-1 flex justify-center items-center rounded-md bg-green-600">
                                    <Icon class="w-5 h-5 text-white" icon="material-symbols:ink-pen" />
                                </button>
                            </td>
                        </tr>
                    </table>
                    <p v-if="isLoading === false && currentStock.length === 0" class="py-10 text-center">No Stock History
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import BtnText from '@/components/common/buttons/btn-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import { getCurrentStock } from '@/services/api/suppliers';
import { Icon } from '@iconify/vue';
import StockAddPopup from '@/components/supply/add-stock-popup';
import editStockPopup from '@/components/supply/edit-current-stock-popup';
import mdText from '@/components/common/titles/md-text.vue';

const { notify } = useNotification();

const isLoading = ref(false);
const currentStock = ref([]);
const isStocksAddingPopupVisible = ref(false);
const isEditStockPopupVisible = ref(false);
const selectedStockToEdit = ref({});

onMounted(() => {
    loadCurrentStock();
});

const loadCurrentStock = async () => {
    isLoading.value = true;
    try {
        const response = await getCurrentStock();
        currentStock.value = response.data;        
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const addStocksPopupOpen = () => {
    isStocksAddingPopupVisible.value = true;
};

const editStocksPopupOpen = (index) => {
    selectedStockToEdit.value = { stock: currentStock.value[index].sizes_stocks, itemId: currentStock.value[index].item_id, supplierId: currentStock.value[index].supplier_id };

    console.log(selectedStockToEdit.value.supplierId);
    
    isEditStockPopupVisible.value = true;
};

const addStocksPopupClose = (val) => {
    isStocksAddingPopupVisible.value = false;

    if (val) {
        loadCurrentStock();
        notify({
            type: "success",
            title: val.title,
            text: val.message,
        });
    }
};

const editStocksPopupClose = (val) => {
    isEditStockPopupVisible.value = false;

    if (val) {
        loadCurrentStock();
        notify({
            type: "success",
            title: val.title,
            text: val.message,
        });
    }
};


</script>