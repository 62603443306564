<template>
    <div @click="itemEditPopupClose()"
        class="flex justify-center items-center fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm bg-[#00000038] z-10">
        <notifications :pauseOnHover="true" />
        <loading :loading="isLoading" />
        <div @click.stop
            class="max-w-[700px] max-h-[80vh] w-full p-5 flex flex-col justify-start rounded-md relative bg-bg-light dark:bg-bg-dark">
            <div class="h-[20px] mb-5">
                <div class="flex justify-end">
                    <button @click="itemEditPopupClose()">
                        <Icon class="w-5 h-5" icon="ic:baseline-close" />
                    </button>
                </div>
            </div>
            <div class="max-h-full overflow-y-auto">
                <div>
                    <div class="form-grid-res">
                        <div class="mb-5">
                            <p>Name</p>
                            <input v-model="name" class="w-full h-12 p-3 rounded-md bg-transparent border"
                                :class="errors.name === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'"
                                type="text" id="name" placeholder="Nike Air Force – 1 Low">
                            <p v-if="errors.name" class="text-sm text-red-600">{{ errors.name }}</p>
                        </div>
                        <div class="mb-5">
                            <p>Category</p>
                            <select v-model="categoryy" class="w-full h-12 p-3 rounded-md bg-transparent border"
                                name="category" id="category"
                                :class="errors.category === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'">
                                <option v-for="(item, index) in categories" :key="index" :value="item.id">{{ item.name }}
                                </option>
                            </select>
                            <p v-if="errors.category" class="text-sm text-red-600">{{ errors.category }}</p>
                        </div>
                        <div class="mb-5">
                            <p>Type</p>
                            <select v-model="typee" class="w-full h-12 p-3 rounded-md bg-transparent border" name="type"
                                id="type"
                                :class="errors.type === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'">
                                <option v-for="(item, index) in type" :key="index" :value="item.id">{{ item.name }}</option>
                            </select>
                            <p v-if="errors.type" class="text-sm text-red-600">{{ errors.type }}</p>
                        </div>
                        <div class="mb-5">
                            <p>Brand</p>
                            <select v-model="brandd" class="w-full h-12 p-3 rounded-md bg-transparent border" name="brand"
                                id="brand"
                                :class="errors.brand === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'">
                                <option v-for="(item, index) in brand" :key="index" :value="item.id">{{ item.name }}
                                </option>
                            </select>
                            <p v-if="errors.brand" class="text-sm text-red-600">{{ errors.brand }}</p>
                        </div>
                        <div class="mb-5">
                            <p>Price</p>
                            <input v-model="price" class="w-full h-12 p-3 rounded-md bg-transparent border"
                                :class="errors.price === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'"
                                type="text" id="name" placeholder="Nike Air Force – 1 Low">
                            <p v-if="errors.price" class="text-sm text-red-600">{{ errors.price }}</p>
                        </div>
                        <div class="mb-5">
                            <p>Discount</p>
                            <input v-model="discount" class="w-full h-12 p-3 rounded-md bg-transparent border"
                                :class="errors.discount === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'"
                                type="text" id="name" placeholder="10">
                            <p v-if="errors.discount" class="text-sm text-red-600">{{ errors.discount }}</p>
                        </div>
                        <div v-if="sizeStockType === 'specified'" class="mb-5">
                            <p class="mb-3">Size - Stock</p>
                            <!-- <div class="flex gap-10">
                                <div>
                                    <input class="mr-2" v-model="sizeStockType" value="specified" type="radio"
                                        name="size-stock" id="size-stock">
                                    <label for="size-stock">Specified</label>
                                </div>
                                <div>
                                    <input class="mr-2" v-model="sizeStockType" value="notSpecified" type="radio"
                                        name="size-stock" id="size-stock">
                                    <label for="size-stock">Not Specified</label>
                                </div>
                            </div> -->
                            <div v-if="sizeStockType === 'specified'" class="mt-5">
                                <div class="flex gap-3">
                                    <div class="mb-5">
                                        <input v-model="size" class="w-full h-11 p-3 rounded-md bg-transparent border"
                                            :class="errors.size === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'"
                                            type="text" id="size" placeholder="XL">
                                        <p v-if="errors.size" class="text-sm text-red-600">{{ errors.size }}</p>
                                    </div>
                                    <div class="mb-5">
                                        <input v-model="stock" class="w-full h-11 p-3 rounded-md bg-transparent border"
                                            :class="errors.stock === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'"
                                            type="text" id="stock" placeholder="5">
                                        <p v-if="errors.stock" class="text-sm text-red-600">{{ errors.stock }}</p>
                                    </div>
                                    <button @click="sizeStockAdd()"
                                        class="w-[44px] h-[44px] rounded-md flex justify-center items-center flex-shrink-0 transition duration-300 ease-in-out bg-primary hover:bg-secondary">
                                        <Icon class="w-5 h-5 text-white" icon="ic:baseline-plus" />
                                    </button>
                                </div>
                                <p class="text-red-600 text-sm">{{ errors.sizeStock }}</p>
                                <div class="flex flex-wrap gap-3">
                                    <div v-for="(item, index) in sizes" :key="index"
                                        class="p-3 rounded-md flex items-center bg-fg-light dark:bg-fg-dark relative">
                                        <button @click="sizeStockRemove(index)"
                                            class="w-4 h-4 bg-[#c1c1c1] dark:bg-[#4c4c4c] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px]">
                                            <Icon class="w-3 h-3 text-white" icon="ri:close-line" />
                                        </button>
                                        <div class="w-7 h-7 border flex justify-center items-center rounded-full">
                                            <p class="text-[12px]">{{ item.size }}</p>
                                        </div>
                                        <p class="mx-2">-</p>
                                        <p>{{ item.stock }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <p class="mb-1">Photos</p>
                            <div class="flex flex-wrap gap-5">
                                <div class="relative bg-fg-light dark:bg-fg-dark">
                                    <!-- <button @click="imgRemove(1)" v-if="img1URL !== null"
                                        class="w-5 h-5 bg-[red] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px] z-10">
                                        <Icon class="w-4 h-4 text-white" icon="teenyicons:minus-small-solid" />
                                    </button> -->
                                    <label for="img1"
                                        class="w-[100px] h-[100px] border rounded-md cursor-pointer flex justify-center items-center overflow-hidden relative"
                                        :class="errors.img !== '' ? 'border-red-600' : 'dark:border-gray-600 '">
                                        <img class="max-w-full max-h-full" v-if="img1URL !== null" :src="img1URL" alt="">
                                        <Icon v-if="img1URL === null" class="w-10 h-10 text-gray-400"
                                            icon="material-symbols:add-a-photo-outline-rounded" />
                                        <input class="w-0 opacity-0" type="file" name="" id="img1"
                                            accept=".jpg, .jpeg, .png, .webp" @change="img1Upload()" ref="img1">
                                    </label>
                                </div>
                                <div v-if="img1URL" class="relative bg-fg-light dark:bg-fg-dark">
                                    <!-- <button @click="imgRemove(2)" v-if="img2URL !== null"
                                        class="w-5 h-5 bg-[red] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px] z-10">
                                        <Icon class="w-4 h-4 text-white" icon="teenyicons:minus-small-solid" />
                                    </button> -->
                                    <label for="img2"
                                        class="w-[100px] h-[100px] border dark:border-gray-600 rounded-md cursor-pointer flex justify-center items-center overflow-hidden relative">
                                        <img class="max-w-full max-h-full" v-if="img2URL !== null" :src="img2URL" alt="">
                                        <Icon v-if="img2URL === null" class="w-10 h-10 text-gray-400"
                                            icon="material-symbols:add-a-photo-outline-rounded" />
                                        <input class="w-0 opacity-0" type="file" name="" id="img2"
                                            accept=".jpg, .jpeg, .png, .webp" @change="img2Upload()" ref="img2">
                                    </label>
                                </div>
                                <div v-if="img2URL" class="relative bg-fg-light dark:bg-fg-dark">
                                    <!-- <button @click="imgRemove(3)" v-if="img3URL !== null"
                                        class="w-5 h-5 bg-[red] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px] z-10">
                                        <Icon class="w-4 h-4 text-white" icon="teenyicons:minus-small-solid" />
                                    </button> -->
                                    <label for="img3"
                                        class="w-[100px] h-[100px] border dark:border-gray-600 rounded-md cursor-pointer flex justify-center items-center overflow-hidden relative">
                                        <img class="max-w-full max-h-full" v-if="img3URL !== null" :src="img3URL" alt="">
                                        <Icon v-if="img3URL === null" class="w-10 h-10 text-gray-400"
                                            icon="material-symbols:add-a-photo-outline-rounded" />
                                        <input class="w-0 opacity-0" type="file" name="" id="img3"
                                            accept=".jpg, .jpeg, .png, .webp" @change="img3Upload()" ref="img3">
                                    </label>
                                </div>
                                <div v-if="img3URL" class="relative bg-fg-light dark:bg-fg-dark">
                                    <!-- <button @click="imgRemove(4)" v-if="img4URL !== null"
                                        class="w-5 h-5 bg-[red] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px] z-10">
                                        <Icon class="w-4 h-4 text-white" icon="teenyicons:minus-small-solid" />
                                    </button> -->
                                    <label for="img4"
                                        class="w-[100px] h-[100px] border dark:border-gray-600 rounded-md cursor-pointer flex justify-center items-center overflow-hidden relative">
                                        <img class="max-w-full max-h-full" v-if="img4URL !== null" :src="img4URL" alt="">
                                        <Icon v-if="img4URL === null" class="w-10 h-10 text-gray-400"
                                            icon="material-symbols:add-a-photo-outline-rounded" />
                                        <input class="w-0 opacity-0" type="file" name="" id="img4"
                                            accept=".jpg, .jpeg, .png, .webp" @change="img4Upload()" ref="img4">
                                    </label>
                                </div>
                            </div>
                            <p class="text-red-600 text-sm">{{ errors.img }}</p>
                        </div>
                    </div>
                    <div class="mb-20">
                        <p class="mb-1">Description</p>
                        <textarea v-model="description"
                            class="w-full p-3 rounded-md border bg-fg-light dark:bg-fg-dark outline-none"
                            :class="errors.description !== '' ? 'border-red-600' : 'dark:border-gray-600'" id="" rows="7"
                            placeholder="Describe the item"></textarea>
                        <p class="text-red-600 text-sm">{{ errors.description }}</p>
                    </div>
                </div>
            </div>
            <div class="absolute bottom-0 left-0 right-0">
                <div class="pb-5 grid place-items-center bg-bg-light dark:bg-bg-dark">
                    <btnText @click-event="updateItemData()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, watch, defineProps, defineEmits } from 'vue';
import { Icon } from '@iconify/vue';
import btnText from '@/components/common/buttons/btn-text.vue';
import loading from '@/components/common/loading-overlay-com.vue';
import { useNotification } from "@kyvg/vue3-notification";
import { editItem } from "@/services/api/items";
import Swal from 'sweetalert2';

const { notify } = useNotification();

const props = defineProps({
    itemData: {
        type: Object,
    },
});

const isLoading = ref(false);
const categories = ref([
    { id: 'shoes', name: 'Shoes' },
    { id: 'pre-designed', name: 'Pre-Designed' },
    { id: 'slippers', name: 'Slippers' },
    { id: 'socks', name: 'Socks' },
    { id: 't-shirts', name: 'T-Shirts' },
    { id: 'jeans', name: 'Jeans' },
    { id: 'shorts', name: 'Shorts' },
    { id: 'paintings', name: 'Paintings' },
    { id: 'others', name: 'Others' },
]);

const brand = ref([
    { id: 'all', name: 'Not Specified' },
    { id: 'adidas', name: 'Adidas' },
    { id: 'levis', name: 'Levis' },
    { id: 'nike', name: 'Nike' },
    { id: 'vans', name: 'Vans' },
]);

const type = ref([
    { id: 'all', name: 'All' },
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' },
]);
const name = ref(props.itemData.name);
const categoryy = ref(props.itemData.category);
const typee = ref(props.itemData.type);
const brandd = ref(props.itemData.brand);
const price = ref(props.itemData.price);
const discount = ref(props.itemData.discount === '0.00' || null || 0 ? '' : props.itemData.discount);
const sizeStockType = ref(props.itemData.sizes === 'all' ? 'notSpecified' : 'specified');
const size = ref('');
const stock = ref('');
const sizes = ref(props.itemData.sizes === 'all' ? null : props.itemData.sizes);
const img1 = ref(null);
const img1File = ref(null);
const img1URL = ref(props.itemData.img_path_1);
const img2 = ref(null);
const img2File = ref(null);
const img2URL = ref(props.itemData.img_path_2 === null ? null : props.itemData.img_path_2);
const img3 = ref(null);
const img3File = ref(null);
const img3URL = ref(props.itemData.img_path_3 === null ? null : props.itemData.img_path_3);
const img4 = ref(null);
const img4File = ref(null);
const img4URL = ref(props.itemData.img_path_4 === null ? null : props.itemData.img_path_4);
const description = ref(props.itemData.description);
const errors = ref({
    name: '',
    description: '',
    price: '',
    discount: '',
    category: '',
    brand: '',
    type: '',
    size: '',
    stock: '',
    sizeStock: '',
    img: '',
    description: '',
});

const sizeStockAdd = () => {
    if (size.value === '') {
        errors.value.size = 'Size is required!';
    } else if (sizes.value !== 'all' && sizes.value.some(item => item.size === size.value)) {
        errors.value.size = 'This type is already added!';
    } else {
        errors.value.size = '';
    }

    if (stock.value === '') {
        errors.value.stock = 'Stock is required!';
    } else if (isNaN(stock.value)) {
        errors.value.stock = 'Invalid stock type!';
    } else {
        errors.value.stock = '';
    }

    if (errors.value.size === '' && errors.value.stock === '') {
        if (isNaN(size.value)) {
            sizes.value.push({ size: (size.value).toUpperCase(), stock: parseInt(stock.value) });
        } else {
            sizes.value.push({ size: size.value, stock: parseInt(stock.value) });
        }
        size.value = '';
        stock.value = '';
        errors.value.sizeStock = '';
    }
};

const sizeStockRemove = (index) => {
    sizes.value.splice(index, 1);
};

const img1Upload = () => {
    img1File.value = img1.value.files[0];
    img1URL.value = URL.createObjectURL(img1File.value);
};

const img2Upload = () => {
    img2File.value = img2.value.files[0];
    img2URL.value = URL.createObjectURL(img2File.value);
};

const img3Upload = () => {
    img3File.value = img3.value.files[0];
    img3URL.value = URL.createObjectURL(img3File.value);
};

const img4Upload = () => {
    img4File.value = img4.value.files[0];
    img4URL.value = URL.createObjectURL(img4File.value);
};

// const imgRemove = (index) => {
//     if (index === 1) {
//         img1.value = null;
//         img1URL.value = null;
//         img1File.value = null;

//         if (img2) {
//             img1.value = img2.value;
//             img1URL.value = img2URL.value;
//             img1File.value = img2File.value;

//             img2.value = null;
//             img2URL.value = null;
//             img2File.value = null;
//         }
//     }

//     if (index === 2) {
//         img2.value = null;
//         img2URL.value = null;
//         img2File.value = null;
//     }

//     if (index === 3) {
//         img3.value = null;
//         img3URL.value = null;
//         img3File.value = null;
//     }

//     if (index === 4) {
//         img4.value = null;
//         img4URL.value = null;
//         img4File.value = null;
//     }
// };

const validation = () => {
    if (name.value === '') {
        errors.value.name = 'Enter the item name!'
    } else {
        errors.value.name = '';
    }

    if (description.value === '') {
        errors.value.description = 'Please enter the description!'
    } else {
        errors.value.description = '';
    }

    if (price.value === '') {
        errors.value.price = 'Please enter the price!';
    } else if (isNaN(price.value)) {
        errors.value.price = 'Invalid price type!'
    } else {
        errors.value.price = '';
    }

    if (isNaN(discount.value)) {
        errors.value.discount = 'Please enther the valid discount';
    } else if (discount.value > 100) {
        errors.value.discount = 'The discount must be 0 to 100';
    } else {
        errors.value.discount = '';
    }

    if (categoryy.value === '') {
        errors.value.category = 'Please select a category!'
    } else {
        errors.value.category = '';
    }

    if (typee.value === '') {
        errors.value.type = 'Please select a type!'
    } else {
        errors.value.type = '';
    }

    if (brandd.value === '') {
        errors.value.brand = 'Please select a brand!'
    } else {
        errors.value.brand = '';
    }

    if (sizeStockType.value === 'specified' && sizes.length === 0) {
        errors.value.sizeStock = 'Add at lease one size and stock!';
    } else {
        errors.value.sizeStock = '';
    }

    if (img1URL.value === null) {
        errors.value.img = 'Pease upload at least one image of the item!'
    } else {
        errors.value.img = '';
    }
};

const updateItemData = async () => {
    isLoading.value = true;
    validation();

    if (errors.value.name === '' && errors.value.category === '' && errors.value.type === '' && errors.value.brand === '' && errors.value.price === '' && errors.value.discount === '' && errors.value.sizeStock === '' && errors.value.img === '' && errors.value.description === '') {
        try {
            const formData = new FormData();

            formData.append('id', props.itemData.id);
            formData.append('name', name.value);
            formData.append('category', categoryy.value);
            formData.append('type', typee.value);
            formData.append('brand', brandd.value);
            formData.append('price', price.value);
            formData.append('discount', discount.value === '' || null ? 0 : discount.value);
            formData.append('sizes', sizeStockType.value === 'specified' ? JSON.stringify(sizes.value) : 'all');
            formData.append('description', description.value);
            formData.append('imageChanges', props.itemData.img_path_1 !== img1URL.value);
            formData.append('imageChanges', props.itemData.img_path_2 !== img2URL.value);
            formData.append('imageChanges', props.itemData.img_path_3 !== img3URL.value);
            formData.append('imageChanges', props.itemData.img_path_4 !== img4URL.value);
            formData.append('img1', img1File.value);
            formData.append('img2', img2File.value);
            formData.append('img3', img3File.value);
            formData.append('img4', img4File.value);

            const response = await editItem(formData);
            itemEditPopupClose(response.data);
        } catch (error) {
            console.log(error);
            notify({
                type: "error",
                title: error.response.data.title,
                text: error.response.data.message,
            });
        }
    }
};

const emits = defineEmits(['itemEditPopupClose']);

const itemEditPopupClose = (response) => {
    emits('itemEditPopupClose', response);
};
</script>