<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <notifications />
        <itemEditPopup v-if="isItemEditPopupVisible" :itemData="selectedItemData" @itemEditPopupClose="itemEditPopupClose"/>
        <div class="forground-container">
            <div class="forground">
                <div class="mb-3 flex justify-between">
                    <mdText :text="'Manage items'" />
                    <div class="flex justify-end gap-5">
                        <select v-model="selectedVisibility" @change="getItemsData()"
                            class="w-[200px] h-10 px-4 rounded-md text-sm bg-fg-light dark:bg-fg-dark border border-[#dedede] dark:border-[#424242]"
                            name="" id="">
                            <option selected value="all">Visibility (All)</option>
                            <option value="1">Visible</option>
                            <option value="0">Hidden</option>
                        </select>
                        <select v-model="selectedCategory" @change="getItemsData()"
                            class="w-[200px] h-10 px-4 rounded-md text-sm bg-fg-light dark:bg-fg-dark border border-[#dedede] dark:border-[#424242]"
                            name="" id="">
                            <option selected value="all">Type (All)</option>
                            <option value="shoes">Shoes</option>
                            <option value="pre-designed">Pre-Designed</option>
                            <option value="slippers">Slippers</option>
                            <option value="socks">Socks</option>
                            <option value="t-shirts">T-Shirts</option>
                            <option value="jeans">Jeans</option>
                            <option value="shorts">Shorts</option>
                            <option value="paintings">Paingings</option>
                            <option value="others">Others</option>
                        </select>
                    </div>
                </div>
                <div>
                    <p v-if="itemsEmptyText !== ''" class="text-center py-20">{{ itemsEmptyText }}</p>
                    <div v-for="(item, index) in items" :key="index"
                        class="py-5 border-b border-b-[#e9e9e9] dark:border-b-[#525252] flex justify-between gap-5">
                        <!-- imgs wrapper -->
                        <div class="w-[200px] flex-shrink-0 relative"
                            :class="item.is_visible === 1 ? 'opacity-100' : 'opacity-30'">
                            <p v-if="item.is_drop" class="px-2 py-1 text-[10px] absolute rounded-md top-2 left-2 border"
                                :class="item.isDropExpired ? 'text-red-600 border-red-600' : 'text-green-500 border-green-500'">
                                DROP</p>
                            <div
                                class="w-[200px] h-[150px] mb-1 bg-fg-light dark:bg-fg-dark flex justify-center items-center">
                                <img class="max-w-full max-h-full" :src="item.img_path_1" alt="">
                            </div>
                            <div class="grid grid-cols-3 gap-1">
                                <div class="w-full h-[60px] bg-fg-light dark:bg-fg-dark flex justify-center items-center">
                                    <img v-if="item.img_path_2 !== null" class="max-w-full max-h-full"
                                        :src="item.img_path_2" alt="">
                                    <Icon v-else class="w-10 h-10 text-[#dddddd] dark:text-[#656565]"
                                        icon="material-symbols:imagesmode-rounded" />
                                </div>
                                <div class="w-full h-[60px] bg-fg-light dark:bg-fg-dark flex justify-center items-center">
                                    <img v-if="item.img_path_3 !== null" class="max-w-full max-h-full"
                                        :src="item.img_path_3" alt="">
                                    <Icon v-else class="w-10 h-10 text-[#dddddd] dark:text-[#656565]"
                                        icon="material-symbols:imagesmode-rounded" />
                                </div>
                                <div class="w-full h-[60px] bg-fg-light dark:bg-fg-dark flex justify-center items-center">
                                    <img v-if="item.img_path_4 !== null" class="max-w-full max-h-full"
                                        :src="item.img_path_4" alt="">
                                    <Icon v-else class="w-10 h-10 text-[#dddddd] dark:text-[#656565]"
                                        icon="material-symbols:imagesmode-rounded" />
                                </div>
                            </div>
                        </div>
                        <!-- item data wrapper -->
                        <div class="w-full" :class="item.is_visible === 1 ? 'opacity-100' : 'opacity-30'">
                            <p class="text-lg font-bold">{{ item.name }}</p>
                            <p class="text-lg font-bold">{{ item.pad.toLocaleString() }}</p>
                            <p v-if="item.discount !== 0" class="text-sm text-red-600"><span class="line-through">{{
                                item.price.toLocaleString() }}</span> ({{ item.discount }}%)</p>
                            <p v-if="item.category !== 'all'" class="text-sm">{{ item.category }}</p>
                            <p v-if="item.brand !== 'all'" class="text-sm">{{ item.brand }}</p>
                            <p v-if="item.type !== 'all'" class="text-sm">{{ item.type }}</p>
                            <div v-if="item.is_drop" class="mt-2 mb-1 flex items-center gap-1"
                                :class="item.isDropExpired ? 'text-red-600' : 'text-green-500'">
                                <p>{{ item.dropCountdown.days }}</p>
                                <p>:</p>
                                <p>{{ item.dropCountdown.hours }}</p>
                                <p>:</p>
                                <p>{{ item.dropCountdown.minutes }}</p>
                                <p>:</p>
                                <p>{{ item.dropCountdown.seconds }}</p>
                            </div>
                            <div v-if="item.sizes !== 'all'" class="pt-3 flex flex-wrap gap-3">
                                <div v-for="(item, index) in item.sizes" :key="index"
                                    class="p-3 rounded-md flex items-center bg-fg-light dark:bg-fg-dark relative">
                                    <div class="w-7 h-7 border flex justify-center items-center rounded-full">
                                        <p class="text-[12px]">{{ item.size }}</p>
                                    </div>
                                    <p class="mx-2">-</p>
                                    <p>{{ item.stock }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- op btns wrapper -->
                        <div class="flex-shrink-0 grid grid-cols-3 gap-2">
                            <button @click="itemEditPopupOpen(index)" class="w-8 h-8 p-1 flex justify-center items-center rounded-md bg-green-600">
                                <Icon class="w-5 h-5 text-white" icon="material-symbols:ink-pen" />
                            </button>
                            <button @click="changeItemVisibilityTrigger(index)"
                                class="w-8 h-8 p-1 flex justify-center items-center rounded-md bg-orange-400">
                                <Icon class="w-5 h-5 text-white"
                                    :icon="item.is_visible === 1 ? 'clarity:eye-show-solid' : 'clarity:eye-hide-solid'" />
                            </button>
                            <!-- <button class="w-8 h-8 p-1 flex justify-center items-center rounded-md bg-red-600">
                                <Icon class="w-5 h-5 text-white" icon="material-symbols-light:delete-forever-rounded" />
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import Loading from '@/components/common/loading-overlay-com.vue';
import mdText from '@/components/common/titles/md-text.vue';
import { Icon } from '@iconify/vue';
import { getItems, changeItemVisibility } from '@/services/api/items';
import Swal from 'sweetalert2';
import { useNotification } from "@kyvg/vue3-notification";
import { CONSTANTS } from '@/utils/constants';
import itemEditPopup from '@/components/items/item-edit-popup-com.vue';

const { notify } = useNotification();

const isLoading = ref(false);
const selectedCategory = ref('all');
const selectedVisibility = ref('all');
const items = ref(null);
const itemsEmptyText = ref('');
const isItemEditPopupVisible = ref(false);
const selectedItemData = ref({});

onMounted(() => {
    getItemsData();
});

const getItemsData = async () => {
    isLoading.value = true;
    try {
        const response = await getItems(selectedCategory.value, selectedVisibility.value);
        items.value = response.data;

        console.log(items.value);
        
        
        items.value.forEach(item => {
            if (item.is_drop && !item.isDropExpired) {
                const endDateTime = new Date(item.drop_end).getTime();
                
                item.dropCountdown = { days: '00', hours: '00', minutes: '00', seconds: '00' };

                const updateCountdown = () => {
                    const currentDateTime = new Date().getTime();
                    const timeLeft = endDateTime - currentDateTime;

                    if (timeLeft <= 0) {
                        clearInterval(intervalId);
                        item.dropCountdown = { days: '00', hours: '00', minutes: '00', seconds: '00' };
                    } else {
                        item.dropCountdown.days = String(Math.floor(timeLeft / (1000 * 60 * 60 * 24))).padStart(2, '0');
                        item.dropCountdown.hours = String(Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
                        item.dropCountdown.minutes = String(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
                        item.dropCountdown.seconds = String(Math.floor((timeLeft % (1000 * 60)) / 1000)).padStart(2, '0');
                    }
                };

                updateCountdown();
                const intervalId = setInterval(updateCountdown, 1000);
            }
        });

        itemsEmptyText.value = items.value.length > 0 ? '' : 'No items to display';
    } catch (error) {
        itemsEmptyText.value = error.message;
    }
    isLoading.value = false;
};

const itemEditPopupOpen = (index) => {
    selectedItemData.value = items.value[index];
    isItemEditPopupVisible.value = true;
};

const itemEditPopupClose = (val) => {
    isItemEditPopupVisible.value = false;

    if(val) {
        getItemsData();
        notify({
            type: "success",
            title: val.title,
            text: val.message,
        });
    }
};

const changeItemVisibilityTrigger = async (index) => {
    try {
        const visibility = items.value[index].is_visible == 1 ? 'hide' : 'show';
        let themeData = localStorage.getItem('themeData');

        Swal.fire({
            title: "Confirmation",
            text: `Are you sure you want to change the item '${items.value[index].name}' visibility to '${visibility}'`,
            showCancelButton: true,
            confirmButtonText: "Save",
            cancelButtonColor: 'red',
            confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
            color: themeData === 'dark' ? 'white' : 'black',
            background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
        }).then(async (result) => {
            if (result.isConfirmed) {
                isLoading.value = true;
                const postData = {
                    itemId: items.value[index].id,
                };

                try {
                    const response = await changeItemVisibility(postData);
                    items.value[index].is_visible = items.value[index].is_visible == 1 ? 0 : 1;
                    notify({
                        type: "success",
                        title: response.data.title,
                        text: response.data.message,
                    });
                } catch (error) {
                    notify({
                        type: "error",
                        title: error.response.data.title,
                        text: error.response.data.message,
                    });
                }
                isLoading.value = false;
            }
        });
        // swal(
        //     'Confirmation?',
        //     `Are you sure you want to change the item '${items.value[index].name}' visibility to '${visibility}'`,
        //     'warning',
        //     true,
        //     'YES',
        //     () => { },

        //     async () => {
        //         console.log('doneeeee');
        //         isLoading.value = true;
        //         const postData = {
        //             itemId: items.value[index].id,
        //         };

        //         try {
        //             const response = await changeItemVisibility(postData);
        //             items.value[index].is_visible = items.value[index].is_visible == 1 ? 0 : 1;
        //             notify({
        //                 type: "success",
        //                 title: response.data.title,
        //                 text: response.data.message,
        //             });
        //         } catch (error) {
        //             notify({
        //                 type: "error",
        //                 title: error.response.data.title,
        //                 text: error.response.data.message,
        //             });
        //         }
        //         isLoading.value = false;
        //     },

        //     () => { }
        // );
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

</script>