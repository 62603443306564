<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <notifications />
        <StaffAddPopup v-if="isStaffAddPopupVisible" @staffAddPopupClose="staffAddPopupClose" />
        <div class="forground-container">
            <div class="forground">
                <div class="mb-5 flex justify-end items-center">
                    <BtnText class="w-[220px]" :icon="'material-symbols:add-circle-outline-rounded'"
                        :buttonText="'Add Member'" @clickEvent="staffAddPopupOpen()" />
                </div>
                <div>
                    <table class="min-w-[700px] w-full">
                        <tr>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">ID</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Name</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Role</th>
                            <th class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Status</th>
                            <th v-if="role === 'admin'" class="px-2 py-4 text-center bg-bg-light dark:bg-bg-dark">Option</th>
                        </tr>
                        <tr v-for="(user, index) in users" :key="index"
                            class="border-b border-gray-200 dark:border-gray-600">
                            <td class="px-2 py-3 text-sm text-center">{{ user.id }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ user.name }}</td>
                            <td class="px-2 py-3 text-sm text-center">{{ user.role }}</td>
                            <td class="w-[100px] px-2 py-3 text-sm text-center"
                                :class="user.is_active === 1 ? 'text-green-600 bg-[#0080002e]' : 'text-red-600 bg-[#ff00002e]'">
                                {{ user.is_active === 1 ? 'Active' : 'Disabled' }}</td>
                            <td v-if="role === 'admin'" class="w-[100px] px-2 py-3 text-sm text-center">
                                <div class="flex justify-center">
                                    <Icon v-if="user.is_active !== 0 && user.role !== 'admin'" @click="deleteUser(index)"
                                        class="w-5 h-5 text-red-600 cursor-pointer"
                                        icon="material-symbols:delete-forever-rounded" />
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from 'vue';
import BtnText from '@/components/common/buttons/btn-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import loading from '@/components/common/loading-overlay-com.vue';
import { getAllStaffUserdata, deleteStaffUser } from '@/services/api/staff';
import { Icon } from '@iconify/vue';
import Swal from 'sweetalert2';
import { CONSTANTS } from '@/utils/constants';
import StaffAddPopup from '@/components/staff/staff-user-add-popup-com.vue';
import store from '@/store/index';

const { notify } = useNotification();

const isLoading = ref(false);
const users = ref([]);
const isStaffAddPopupVisible = ref(false);
const role = ref(null);

onMounted(async() => {
    loadAllStaffUserData();
    role.value = await store.getters.role;
})

watch(() => store.getters.role, async (newRole) => {
    role.value = await newRole;
});

const loadAllStaffUserData = async () => {
    isLoading.value = true;
    try {
        const response = await getAllStaffUserdata();
        users.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const deleteUser = async (index) => {
    let themeData = localStorage.getItem('themeData');

    Swal.fire({
        title: "Confirmation",
        text: `Are you sure you want to disable this user?`,
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonColor: 'red',
        confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
        color: themeData === 'dark' ? 'white' : 'black',
        background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
    }).then(async (result) => {
        if (result.isConfirmed) {
            if (users.value[index].is_active !== 0) {
                isLoading.value = true;
                try {
                    const response = await deleteStaffUser(users.value[index].id);
                    loadAllStaffUserData();
                    notify({
                        type: "success",
                        title: response.data.title,
                        text: response.data.message,
                    });
                } catch (error) {
                    notify({
                        type: "error",
                        title: error.response.data.title,
                        text: error.response.data.message,
                    });
                }
                isLoading.value = false;
            } else {
                notify({
                    type: "error",
                    title: "Error!",
                    text: "This user has been already disabled!",
                });
            }
        }
    });
};

const staffAddPopupOpen = () => {
    isStaffAddPopupVisible.value = true;
};

const staffAddPopupClose = (val) => {
    isStaffAddPopupVisible.value = false;

    if (val) {
        loadAllStaffUserData();
        notify({
            type: "success",
            title: val.title,
            text: val.message,
        });
    }
};

</script>