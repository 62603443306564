import axios from "axios";
import { CONSTANTS } from '@/utils/constants';
import store from '@/store/index';

// get most viewed items count
export const getMostViewedItems = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/analytics/most-viewed-items`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get order summary count
export const getOrdersSummarizeCount = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/analytics/orders-summarize-count`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get order summary count
export const getActiveOrders = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/orders/get-all-orders/accepted`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get latest months 
export const getLatestMonthsOrders = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/analytics/latest-months-orders`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};