<template>
    <swiper :slidesPerView="slidePerView" :spaceBetween="20" :modules="modules" :loop="true"
        :autoplay="{ delay: 2000, disableOnInteraction: false }" class="reviews-swiper">
        <swiper-slide v-for="(item, index) in items" :key="index">
            <router-link :to="`shop/item/${item.id}`">
                <div class="item-box">
                    <div class="item-card bg-fg-light dark:bg-fg-dark">
                        <div class="box-img" :style="`background-color: ${item.color_code.colorDark}`">
                            <img :src="item.img_path_1" alt="">
                        </div>
                        <div class="box-content">
                            <div class="text-black dark:text-white">
                                <p class="txt-title">{{ item.name }}</p>
                                <p class="txt-price">RS. {{ item.price.toLocaleString() }}</p>
                                <p class="txt-type">For {{ item.type }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>
        </swiper-slide>
    </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

export default {

    data() {
        return {
            modules: [Autoplay],
        }
    },

    props: {
        items: {
            type: Object,
            default: null,
        }
    },

    components: {
        Swiper,
        SwiperSlide,
    },

    computed: {
        slidePerView() {
            if (window.innerWidth >= 1700) {
                return 6;
            } else if (window.innerWidth >= 1600) {
                return 5;
            } else if (window.innerWidth >= 1300) {
                return 4;
            } else if (window.innerWidth >= 1000) {
                return 3
            } else if (window.innerWidth >= 600) {
                return 2
            } else {
                return 'auto';
            }
        }
    },

    mounted() {
        this.setCardHeight();
        window.addEventListener('resize', this.setCardHeight);
    },

    methods: {

        setCardHeight() {
            const cards = document.querySelectorAll(".reviews-swiper .swiper-slide .content-box");
            const maxCardHeight = Math.max(...Array.from(cards).map((card) => card.clientHeight));
            cards.forEach((card) => {
                card.style.height = maxCardHeight - 100 + "px";
            });
        },
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.setCardHeight);
    },
};
</script>

<style scoped>
.swiper {
    width: 100%;
    height: 100%;
}

.item-box {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: flex-end;
}

.item-card {
    width: 90%;
    height: 85%;
    border-radius: 10px;
    display: flex;
}

.box-img {
    width: 90px;
    height: 100%;
    flex-shrink: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
}

.box-img img {
    max-width: 110%;
    position: relative;
    right: 25px;
    top: 10px;
    transform: rotate(-35deg);
}

.box-content {
    width: 100%;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.txt-title {
    margin-bottom: 5px;
    font-size: 10px;
    font-family: 'MontserratSemiBold';
}

.txt-price {
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: bold;
}

.txt-type {
    font-size: 10px;
}

.txt-addcart {
    font-size: 12px;
    color: var(--primary-col);
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .swiper-slide {
        width: 60%;
    }
}

@media only screen and (max-width: 450px) {
    .swiper-slide {
        width: 80%;
    }
}

@media only screen and (max-width: 300px) {
    .swiper-slide {
        width: 100%;
    }
}
</style>
  