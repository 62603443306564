<template>
    <div class="container-w-full mt-[70px]">
        <loading :loading="isLoading" />
        <div class="forground-container">
            <div class="forground">
                <mdText :text="'Add a new item'" />
                <div class="form-grid-res">
                    <labelText :id="'name'" :type="'text'" :label="'Name'" :value="item.name"
                        :placeholder="'Nike Air Force – 1 Low'" :error="errors.name"
                        @input-field-value-changed="nameValuechanged" />
                    <labelSelect :id="'category'" :label="'Category'" :value="item.category" :options="categories"
                        :extra-option="{ name: 'Select an option', value: '', isVisible: true }" :error="errors.category"
                        @input-field-value-changed="categoryValuechanged" />
                    <labelSelect :id="'type'" :label="'Type'" :value="item.type" :options="type"
                        :extra-option="{ name: 'Select an option', value: '', isVisible: true }" :error="errors.type"
                        @input-field-value-changed="typeValuechanged" />
                    <labelSelect :id="'brand'" :label="'brand'" :value="item.brand" :options="brand"
                        :extra-option="{ name: 'Select an option', value: '', isVisible: true }" :error="errors.brand"
                        @input-field-value-changed="brandValuechanged" />
                    <labelText :id="'price'" :type="'text'" :label="'Price'" :value="item.price" :placeholder="'5000'"
                        :error="errors.price" @input-field-value-changed="priceValuechanged" />
                    <labelText :id="'discount'" :type="'text'" :label="'Discount (%)'" :value="item.discount"
                        :placeholder="'10'" :error="errors.discount" @input-field-value-changed="discountValuechanged" />
                    <div class="mb-5">
                        <p class="mb-3">Size - Stock</p>
                        <div class="flex gap-10">
                            <div>
                                <input class="mr-2" v-model="sizeStockType" value="specified" type="radio" name="size-stock"
                                    id="size-stock">
                                <label for="size-stock">Specified</label>
                            </div>
                            <div>
                                <input class="mr-2" v-model="sizeStockType" value="notSpecified" type="radio"
                                    name="size-stock" id="size-stock">
                                <label for="size-stock">Not Specified</label>
                            </div>
                        </div>
                        <transition name="sizeStock">
                            <div v-if="sizeStockType === 'specified'" class="mt-5">
                                <div class="flex gap-3">
                                    <labelText :id="'price'" :type="'text'" :label="''" :value="size"
                                        :placeholder="'Size (10 / 12 / XL)'" :error="errors.size"
                                        @input-field-value-changed="sizeValuechanged" />
                                    <labelText :id="'price'" :type="'text'" :label="''" :value="stock"
                                        :placeholder="'Stock (5)'" :error="errors.stock"
                                        @input-field-value-changed="stockValuechanged" />
                                    <button @click="sizeStockAdd()"
                                        class="w-[44px] h-[44px] mt-1 rounded-md flex justify-center items-center flex-shrink-0 transition duration-300 ease-in-out bg-primary hover:bg-secondary">
                                        <Icon class="w-5 h-5 text-white" icon="ic:baseline-plus" />
                                    </button>
                                </div>
                                <p class="text-red-600 text-sm">{{ errors.sizeStock }}</p>
                                <div class="flex flex-wrap gap-3">
                                    <div v-for="(item, index) in item.sizes" :key="index"
                                        class="p-3 rounded-md flex items-center bg-fg-light dark:bg-fg-dark relative">
                                        <button @click="sizeStockRemove(index)"
                                            class="w-4 h-4 bg-[#c1c1c1] dark:bg-[#4c4c4c] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px]">
                                            <Icon class="w-3 h-3 text-white" icon="ri:close-line" />
                                        </button>
                                        <div class="w-7 h-7 border flex justify-center items-center rounded-full">
                                            <p class="text-[12px]">{{ item.size }}</p>
                                        </div>
                                        <p class="mx-2">-</p>
                                        <p>{{ item.stock }}</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="mb-5">
                        <p class="mb-1">Photos</p>
                        <div class="flex flex-wrap gap-5">
                            <div class="relative bg-fg-light dark:bg-fg-dark">
                                <!-- <button v-if="img1URL !== null"
                                    class="w-5 h-5 bg-[red] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px] z-10">
                                    <Icon class="w-4 h-4 text-white" icon="teenyicons:minus-small-solid" />
                                </button> -->
                                <label for="img1"
                                    class="w-[100px] h-[100px] border rounded-md cursor-pointer flex justify-center items-center overflow-hidden relative"
                                    :class="errors.img !== '' ? 'border-red-600' : 'dark:border-gray-600 '">
                                    <img class="max-w-full max-h-full" v-if="img1URL !== null" :src="img1URL" alt="">
                                    <Icon v-if="!img1URL" class="w-10 h-10 text-gray-400"
                                        icon="material-symbols:add-a-photo-outline-rounded" />
                                    <input class="w-0 opacity-0" type="file" name="" id="img1"
                                        accept=".jpg, .jpeg, .png, .webp" @change="img1Upload()" ref="img1">
                                </label>
                            </div>
                            <div v-if="img1URL" class="relative bg-fg-light dark:bg-fg-dark">
                                <!-- <button v-if="img2URL !== null"
                                    class="w-5 h-5 bg-[red] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px] z-10">
                                    <Icon class="w-4 h-4 text-white" icon="teenyicons:minus-small-solid" />
                                </button> -->
                                <label for="img2"
                                    class="w-[100px] h-[100px] border dark:border-gray-600 rounded-md cursor-pointer flex justify-center items-center overflow-hidden relative">
                                    <img class="max-w-full max-h-full" v-if="img2URL !== null" :src="img2URL" alt="">
                                    <Icon v-if="!img2URL" class="w-10 h-10 text-gray-400"
                                        icon="material-symbols:add-a-photo-outline-rounded" />
                                    <input class="w-0 opacity-0" type="file" name="" id="img2"
                                        accept=".jpg, .jpeg, .png, .webp" @change="img2Upload()" ref="img2">
                                </label>
                            </div>
                            <div v-if="img2URL" class="relative bg-fg-light dark:bg-fg-dark">
                                <!-- <button v-if="img3URL !== null"
                                    class="w-5 h-5 bg-[red] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px] z-10">
                                    <Icon class="w-4 h-4 text-white" icon="teenyicons:minus-small-solid" />
                                </button> -->
                                <label for="img3"
                                    class="w-[100px] h-[100px] border dark:border-gray-600 rounded-md cursor-pointer flex justify-center items-center overflow-hidden relative">
                                    <img class="max-w-full max-h-full" v-if="img3URL !== null" :src="img3URL" alt="">
                                    <Icon v-if="!img3URL" class="w-10 h-10 text-gray-400"
                                        icon="material-symbols:add-a-photo-outline-rounded" />
                                    <input class="w-0 opacity-0" type="file" name="" id="img3"
                                        accept=".jpg, .jpeg, .png, .webp" @change="img3Upload()" ref="img3">
                                </label>
                            </div>
                            <div v-if="img3URL" class="relative bg-fg-light dark:bg-fg-dark">
                                <!-- <button v-if="img4URL !== null"
                                    class="w-5 h-5 bg-[red] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px] z-10">
                                    <Icon class="w-4 h-4 text-white" icon="teenyicons:minus-small-solid" />
                                </button> -->
                                <label for="img4"
                                    class="w-[100px] h-[100px] border dark:border-gray-600 rounded-md cursor-pointer flex justify-center items-center overflow-hidden relative">
                                    <img class="max-w-full max-h-full" v-if="img4URL !== null" :src="img4URL" alt="">
                                    <Icon v-if="!img4URL" class="w-10 h-10 text-gray-400"
                                        icon="material-symbols:add-a-photo-outline-rounded" />
                                    <input class="w-0 opacity-0" type="file" name="" id="img4"
                                        accept=".jpg, .jpeg, .png, .webp" @change="img4Upload()" ref="img4">
                                </label>
                            </div>
                        </div>
                        <p class="text-red-600 text-sm">{{ errors.img }}</p>
                    </div>
                    <div class="mb-5">
                        <p class="mb-3">Mark as a drop item</p>
                        <div class="flex gap-10">
                            <div>
                                <input class="mr-2" v-model="itemType" value="default" type="radio" name="item-type"
                                    id="item-type">
                                <label for="item-type">No</label>
                            </div>
                            <div>
                                <input class="mr-2" v-model="itemType" value="drop" type="radio" name="item-type"
                                    id="item-type">
                                <label for="item-type">Yes</label>
                            </div>
                        </div>
                        <transition name="sizeStock">
                            <div v-if="itemType === 'drop'" class="mt-3 grid grid-cols-2 gap-3">
                                <labelText :id="'dropEndDate'" :type="'date'" :label="'End Date'" :value="dropEndDate"
                                    :placeholder="'Nike Air Force – 1 Low'" :error="errors.dropEndDate"
                                    @input-field-value-changed="dropEndDateChanged" />
                                <labelText :id="'dropEndTime'" :type="'time'" :label="'End Time'" :value="dropEndTime"
                                    :placeholder="'Nike Air Force – 1 Low'" :error="errors.dropEndTime"
                                    @input-field-value-changed="dropEndTimeChanged" />
                            </div>
                        </transition>
                    </div>
                </div>
                <div>
                    <p class="mb-1">Description</p>
                    <textarea v-model="item.description"
                        class="w-full p-3 rounded-md border bg-fg-light dark:bg-fg-dark outline-none"
                        :class="errors.description !== '' ? 'border-red-600' : 'dark:border-gray-600'" id="" rows="7"
                        placeholder="Describe the item"></textarea>
                    <p class="text-red-600 text-sm">{{ errors.description }}</p>
                </div>
                <div class="form-grid-res">
                    <btnText @click-event="submitAddItemForm()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import mdText from '@/components/common/titles/md-text.vue';
import labelText from '@/components/common/label-input-types/label-text.vue';
import labelSelect from '@/components/common/label-input-types/label-select.vue';
import btnText from '@/components/common/buttons/btn-text.vue';
import loading from '@/components/common/loading-overlay-com.vue';
import { Icon } from '@iconify/vue';
import { addItem } from '@/services/api/items';
import { swal } from '@/utils/helper';
// import { CONSTANTS } from '@/utils/constants';

const isLoading = ref(false);
const sizeStockType = ref('specified');
const itemType = ref('default');

const categories = ref([
    { id: 'shoes', name: 'Shoes' },
    { id: 'pre-designed', name: 'Pre-Designed' },
    { id: 'slippers', name: 'Slippers' },
    { id: 'socks', name: 'Socks' },
    { id: 't-shirts', name: 'T-Shirts' },
    { id: 'jeans', name: 'Jeans' },
    { id: 'shorts', name: 'Shorts' },
    { id: 'paintings', name: 'Paintings' },
    { id: 'others', name: 'Others' },
]);

const brand = ref([
    { id: 'all', name: 'Not Specified' },
    { id: 'adidas', name: 'Adidas' },
    { id: 'levis', name: 'Levis' },
    { id: 'nike', name: 'Nike' },
    { id: 'vans', name: 'Vans' },
]);

const type = ref([
    { id: 'all', name: 'All' },
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' },
]);

const size = ref('');
const stock = ref('');
const dropEndDate = ref('');
const dropEndTime = ref('');

const item = ref({
    name: '',
    description: '',
    price: '',
    discount: 0,
    category: '',
    brand: '',
    type: '',
    sizes: [],
    isDrop: 0,
});

const img1 = ref(null);
const img1File = ref(null);
const img1URL = ref(null);
const img2 = ref(null);
const img2File = ref(null);
const img2URL = ref(null);
const img3 = ref(null);
const img3File = ref(null);
const img3URL = ref(null);
const img4 = ref(null);
const img4File = ref(null);
const img4URL = ref(null);

const errors = ref({
    name: '',
    description: '',
    price: '',
    discount: '',
    category: '',
    brand: '',
    type: '',
    size: '',
    stock: '',
    sizeStock: '',
    img: '',
    dropEndDate: '',
    dropEndTime: '',
});

const nameValuechanged = (val) => {
    item.value.name = val;
    errors.value.name = '';
};

const categoryValuechanged = (val) => {
    item.value.category = val;
    errors.value.category = '';
};

const typeValuechanged = (val) => {
    item.value.type = val;
    errors.value.type = '';
};

const brandValuechanged = (val) => {
    item.value.brand = val;
    errors.value.brand = '';
};

const priceValuechanged = (val) => {
    item.value.price = val;
    errors.value.price = '';
};

const discountValuechanged = (val) => {
    item.value.discount = val;
    errors.value.discount = '';
};

const sizeValuechanged = (val) => {
    size.value = val;
    errors.value.size = '';
};

const stockValuechanged = (val) => {
    stock.value = val;
    errors.value.stock = '';
};

const dropEndDateChanged = (val) => {
    dropEndDate.value = val;
    errors.value.dropEndDate = '';
};

const dropEndTimeChanged = (val) => {
    dropEndTime.value = val;
    errors.value.dropEndTime = '';
};

const validation = () => {
    if (item.value.name === '') {
        errors.value.name = 'Enter the item name!'
    } else {
        errors.value.name = '';
    }

    if (item.value.description === '') {
        errors.value.description = 'Please enter the description!'
    } else {
        errors.value.description = '';
    }

    if (item.value.price === '') {
        errors.value.price = 'Please enter the price!';
    } else if (isNaN(item.value.price)) {
        errors.value.price = 'Invalid price type!'
    } else {
        errors.value.price = '';
    }

    if (isNaN(item.value.discount)) {
        errors.value.discount = 'Please enther the valid discount';
    } else if (item.value.discount > 100) {
        errors.value.discount = 'The discount must be 0 to 100';
    } else {
        errors.value.discount = '';
    }

    if (item.value.category === '') {
        errors.value.category = 'Please select a category!'
    } else {
        errors.value.category = '';
    }

    if (item.value.type === '') {
        errors.value.type = 'Please select a type!'
    } else {
        errors.value.type = '';
    }

    if (item.value.brand === '') {
        errors.value.brand = 'Please select a brand!'
    } else {
        errors.value.brand = '';
    }

    if (sizeStockType.value === 'specified' && item.value.sizes.length === 0) {
        errors.value.sizeStock = 'Add at lease one size and stock!';
    } else {
        errors.value.sizeStock = '';
    }

    if (img1URL.value === null) {
        errors.value.img = 'Pease upload at least one image of the item!'
    } else {
        errors.value.img = '';
    }

    if (itemType.value === 'drop' && dropEndDate.value === '') {
        errors.value.dropEndDate = 'End date is required!';
    } else {
        errors.value.dropEndDate = '';
    }

    if (itemType.value === 'drop' && dropEndTime.value === '') {
        errors.value.dropEndTime = 'End time is required!';
    } else {
        errors.value.dropEndTime = '';
    }
};

const sizeStockAdd = () => {
    if (size.value === '') {
        errors.value.size = 'Size is required!';
    } else if (item.value.sizes !== 'all' && item.value.sizes.some(item => item.size === size.value)) {
        errors.value.size = 'This type is already added!';
    } else {
        errors.value.size = '';
    }

    if (stock.value === '') {
        errors.value.stock = 'Stock is required!';
    } else if (isNaN(stock.value)) {
        errors.value.stock = 'Invalid stock type!';
    } else {
        errors.value.stock = '';
    }

    if (errors.value.size === '' && errors.value.stock === '') {
        if (isNaN(size.value)) {
            item.value.sizes.push({ size: (size.value).toUpperCase(), stock: parseInt(stock.value) });
        } else {
            item.value.sizes.push({ size: size.value, stock: parseInt(stock.value) });
        }
        size.value = '';
        stock.value = '';
        errors.value.sizeStock = '';
    }
};

const sizeStockRemove = (index) => {
    item.value.sizes.splice(index, 1);
};

const img1Upload = () => {
    img1File.value = img1.value.files[0];
    img1URL.value = URL.createObjectURL(img1File.value);
};

const img2Upload = () => {
    img2File.value = img2.value.files[0];
    img2URL.value = URL.createObjectURL(img2File.value);
};

const img3Upload = () => {
    img3File.value = img3.value.files[0];
    img3URL.value = URL.createObjectURL(img3File.value);
};

const img4Upload = () => {
    img4File.value = img4.value.files[0];
    img4URL.value = URL.createObjectURL(img4File.value);
};

const submitAddItemForm = async () => {
    validation();
    isLoading.value = true;
    if (errors.value.name === '' && errors.value.category === '' && errors.value.type === '' && errors.value.brand === '' && errors.value.price === '' && errors.value.discount === '' && errors.value.sizeStock === '' && errors.value.img === '' && errors.value.description === '') {

        try {
            const formData = new FormData();

            formData.append('name', item.value.name);
            formData.append('category', item.value.category);
            formData.append('type', item.value.type);
            formData.append('brand', item.value.brand);
            formData.append('price', item.value.price);
            formData.append('discount', item.value.discount === '' || null ? 0 : item.value.discount);
            formData.append('sizes', sizeStockType.value === 'specified' ? JSON.stringify(item.value.sizes) : 'all');
            formData.append('description', item.value.description);
            formData.append('images', img1File.value);
            formData.append('images', img2File.value);
            formData.append('images', img3File.value);
            formData.append('images', img4File.value);
            formData.append('isDrop', itemType.value === 'drop' ? 1 : 0);
            formData.append('dropEndDate', dropEndDate.value);
            formData.append('dropEndTime', dropEndTime.value);

            const response = await addItem(formData);
            swal(response.data.title, response.data.message, 'success', false, 'OK', () => { }, () => { }, () => { });

            item.value.name = '';
            item.value.description = '';
            item.value.price = '';
            item.value.discount = 0;
            item.value.category = '';
            item.value.brand = '';
            item.value.type = '';
            sizeStockType.value = 'specified';
            item.value.sizes = [];
            img1.value = null;
            img1URL.value = null;
            img1File.value = null;
            img2.value = null;
            img2URL.value = null;
            img2File.value = null;
            img3.value = null;
            img3URL.value = null;
            img3File.value = null;
            img4.value = null;
            img4URL.value = null;
            img4File.value = null;
            dropEndDate.value = '';
            dropEndTime.value = '';
            itemType.value = 'default';
        } catch (error) {
            swal(error.response.data.title, error.response.data.message, 'error', false, 'OK', () => { }, () => { }, () => { });
        }
    }
    isLoading.value = false;
};

</script>

<style scoped>
.sizeStock-enter-from,
.sizeStock-leave-to {
    opacity: 0;
}

.sizeStock-enter-active,
.sizeStock-leave-active {
    transition: all .2s ease;
}
</style>