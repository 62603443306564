<template>
    <div @click="itemInfoPopupClose()"
        class="flex justify-center items-center fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm bg-[#00000038] z-10">
        <loading :loading="isLoading" />
        <notifications />
        <div @click.stop
            class="max-w-[700px] max-h-[80vh] w-full p-5 flex flex-col justify-start rounded-md bg-bg-light dark:bg-bg-dark">
            <div class="h-[80px] mb-5">
                <div class="flex justify-end">
                    <button @click="itemInfoPopupClose()">
                        <Icon class="w-5 h-5" icon="ic:baseline-close" />
                    </button>
                </div>
                <p class="mb-1 text-center text-[12px]">{{ props.orderData.id }}</p>
                <p class="text-center text-[12px] font-bold"
                    :class="props.orderData.status === 'pending' ? 'text-[#FFA500]' : props.orderData.status === 'accepted' ? 'text-[#1E90FF]' : props.orderData.status === 'delivered' ? 'text-[#2b902b]' : props.orderData.status === 'completed' ? 'text-[#32CD32]' : 'text-[#FF0000]'">
                    {{ props.orderData.status.toUpperCase() }}</p>
            </div>
            <div class="max-h-full overflow-y-auto">
                <div class="mb-10">
                    <mdIconText :icon="'material-symbols:account-circle'" :text="'User Data'" />
                    <div class="w-full h-[1px] mb-3 bg-fg-light dark:bg-fg-dark"></div>
                    <p>User ID: {{ props.orderData.user_id }}</p>
                    <p>User Name: {{ props.orderData.first_name + ' ' + props.orderData.last_name }}</p>
                    <p>Phone 1: {{ props.orderData.phone_1 }}</p>
                    <p>Phone 2: {{ props.orderData.phone_2 }}</p>
                    <div class="flex gap-3">
                        <p>Address:</p>
                        <div>
                            <p>{{ props.orderData.address_line_1 }}, <br>{{ props.orderData.address_line_2 }}.</p>
                        </div>
                    </div>
                    <!-- <p>Address: {{ props.orderData.address_line_1 }}, <br>{{ props.orderData.address_line_2 }}.</p> -->
                    <p>City: {{ props.orderData.city }}</p>
                    <p>Province: {{ props.orderData.province }}</p>
                    <p>Postal Code: {{ props.orderData.postal_code }}</p>
                </div>
                <div class="mb-10">
                    <mdIconText :icon="'mdi:tshirt-crew'" :text="'Item Data'" />
                    <div class="w-full h-[1px] mb-3 bg-fg-light dark:bg-fg-dark"></div>
                    <p>Item Type: {{ props.orderData.type }}</p>
                    <div>
                        <p v-if="props.orderData.item_id">Item ID: {{ props.orderData.item_id }}</p>
                        <p v-if="props.orderData.customize_id">Customize ID: {{ props.orderData.customize_id }}</p>
                    </div>
                    <p>Item Name: {{ props.orderData.item_name }}</p>
                    <p>Item Size: {{ props.orderData.size === null ? '---' : props.orderData.size }}</p>
                    <p>Item Quantity: {{ props.orderData.quantity === null ? '---' : props.orderData.quantity }}</p>
                </div>
                <div class="mb-10">
                    <mdIconText :icon="'material-symbols:order-play-rounded'" :text="'Order Data'" />
                    <div class="w-full h-[1px] mb-3 bg-fg-light dark:bg-fg-dark"></div>
                    <p>Ordered Date: {{ props.orderData.ordered_date_time.date_string }}</p>
                    <p>Price: {{ props.orderData.price.toLocaleString() }}</p>
                    <p>Discount: {{ props.orderData.discount }}%</p>
                    <p>Total: {{ props.orderData.total.toLocaleString() }}</p>
                    <p>Payment Type: {{ props.orderData.payment_type }}</p>
                    <div v-if="props.orderData.payment_type === 'bank'">
                        <p>Bank Slip</p>
                        <div class="w-[150px] h-[100px] flex justify-center items-center bg-fg-light dark:bg-fg-dark">
                            <Icon v-if="props.orderData.slip_path === null" class="w-10 h-10"
                                icon="material-symbols:imagesmode-rounded" />
                            <img v-else class="w-full h-full object-cover" :src="props.orderData.slip_path" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="props.orderData.status === 'cancelled'" class="pt-5">
                <p class="text-sm text-red-600 text-center">{{ props.orderData.note }}</p>
            </div>
            <div v-if="props.orderData.status !== 'cancelled' && props.orderData.status !== 'delivered' && props.orderData.status !== 'completed'"
                class="pt-5">
                <div>
                    <!-- <div v-if="props.orderData.status === 'pending'" class="max-w-[400px] w-full mb-5 mx-auto">
                        <p class="mb-1">Estimated Delivery Date</p>
                        <input v-model="expectedDeliveryDate" class="w-full h-10 p-3 rounded-md bg-transparent border"
                            :class="errors.expectedDeliveryDate === '' ? 'border-fg-light dark:border-[#ffffff47]' : 'border-red-600'"
                            type="date" placeholder="Expected Delivery Date">
                        <p v-if="errors.expectedDeliveryDate" class="text-sm text-red-600">{{ errors.expectedDeliveryDate }}
                        </p>
                    </div> -->
                    <div class="flex justify-center gap-3">
                        <button @click="orderStatusChange()" class="py-4 px-6 rounded-md"
                            :class="props.orderData.status === 'pending' ? 'text-[#1E90FF] bg-[#1e8fff46]' : 'text-[#2b902b] bg-[#05740546]'">
                            {{ props.orderData.status === 'pending' ? 'Accept' : 'Deliver' }} Order</button>
                        <button v-if="props.orderData.status === 'pending'" @click="orderStatusCancel()"
                            class="py-4 px-6 rounded-md text-[#FF0000] bg-[#ff000046]">Cancel Order</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, defineEmits } from 'vue';
import { Icon } from '@iconify/vue';
import loading from '@/components/common/loading-overlay-com.vue';
import mdIconText from '@/components/common/titles/md-icon-text.vue';
import { useNotification } from "@kyvg/vue3-notification";
import { changeStatus } from '@/services/api/orders';
import Swal from 'sweetalert2';
import { CONSTANTS } from '@/utils/constants';

const { notify } = useNotification();

const props = defineProps({
    orderData: {
        type: Object,
    },
});

const isLoading = ref(false);
const expectedDeliveryDate = ref('');

const orderStatusChange = async () => {
    const status = props.orderData.status === 'pending' ? 'accepted' : props.orderData.status === 'accepted' ? 'delivered' : props.orderData.status;
    let themeData = localStorage.getItem('themeData');
    if (status === 'accepted') {
        Swal.fire({
            title: "Confirmation",
            text: `Please type the estimated delivery date`,
            input: "date",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Save",
            cancelButtonColor: 'red',
            confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
            color: themeData === 'dark' ? 'white' : 'black',
            background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
            preConfirm: (value) => {
                if (!value) {
                    Swal.showValidationMessage('Estimated delivery date is required!');
                }
                return value;
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                isLoading.value = true;

                try {
                    const postData = {
                        id: props.orderData.id,
                        status: status,
                        expectedDeliveryDate: result.value,
                        userId: props.orderData.user_id,
                        username: props.orderData.first_name + ' ' + props.orderData.last_name,
                        itemName: props.orderData.item_name,
                        note: null,
                    };

                    const response = await changeStatus(postData);
                    itemInfoPopupClose(response.data);
                } catch (error) {
                    notify({
                        type: "error",
                        title: error.response.data.title,
                        text: error.response.data.message,
                    });
                }
                isLoading.value = false;
            }
        });
    } else {
        Swal.fire({
            title: "Confirmation",
            text: `Are you sure you want to change the status of this order'?`,
            showCancelButton: true,
            confirmButtonText: "Save",
            cancelButtonColor: 'red',
            confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
            color: themeData === 'dark' ? 'white' : 'black',
            background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
        }).then(async (result) => {
            if (result.isConfirmed) {
                isLoading.value = true;

                try {
                    const postData = {
                        id: props.orderData.id,
                        status: status,
                        expectedDeliveryDate: expectedDeliveryDate.value,
                        userId: props.orderData.user_id,
                        username: props.orderData.first_name + ' ' + props.orderData.last_name,
                        itemName: props.orderData.item_name,
                        note: null,
                    };

                    const response = await changeStatus(postData);
                    itemInfoPopupClose(response.data);
                } catch (error) {
                    notify({
                        type: "error",
                        title: error.response.data.title,
                        text: error.response.data.message,
                    });
                }
                isLoading.value = false;
            }
        });
    }
};

const orderStatusCancel = async () => {
    let themeData = localStorage.getItem('themeData');
    Swal.fire({
        title: "Confirmation",
        text: `Please type the reason why this order should be cancelled?`,
        input: "text",
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonColor: 'red',
        confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
        color: themeData === 'dark' ? 'white' : 'black',
        background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
        preConfirm: (value) => {
            if (!value) {
                Swal.showValidationMessage('Reason is required!');
            }
            return value;
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            isLoading.value = true;

            try {
                const postData = {
                    id: props.orderData.id,
                    status: 'cancelled',
                    note: result.value,
                    expectedDeliveryDate: null,
                    userId: props.orderData.user_id,
                    username: props.orderData.first_name + ' ' + props.orderData.last_name,
                    itemName: props.orderData.item_name,
                };

                const response = await changeStatus(postData);
                itemInfoPopupClose(response.data);
            } catch (error) {
                notify({
                    type: "error",
                    title: error.response.data.title,
                    text: error.response.data.message,
                });
            }
            isLoading.value = false;
        }
    });
};

const emits = defineEmits(['newsletterStatusChangePopupClose', 'orderStatusChange']);

const itemInfoPopupClose = (response) => {
    emits('itemInfoPopupClose', response);
};
</script>