import axios from "axios";
import { CONSTANTS } from '@/utils/constants';
import store from '@/store/index';

// add item
export const addItem = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/items/add-item`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get items
export const getItems = async (category, visibility) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-all-items/${category}/${visibility}/?searchKey=`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

//edit item
export const editItem = async (data) => {
    const response = await axios.patch(`${CONSTANTS.BASE_URL}/items/update-item`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

//change item visibility
export const changeItemVisibility = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/items/change-item-visibility`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

//get featured items
export const getFeaturedItems = async (type, filter) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-all-featured-items/${type}/${filter}`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

//add item to featured
export const addItemToFeatured = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/items/add-item-to-featured`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

//remove item from featured
export const removeItemFromFeatured = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/items/remove-item-from-featured`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

//get banner items
export const getBannerSliderItems = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-banner-slider-items`);
    return response;
}