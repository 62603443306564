<template>
    <div class="container-w-full min-h-screen pt-24">
        <loading :loading="isLoading" />
        <notifications />
        <div class="px-[5px] md:px-[15px] mb-10">
            <div class="container pl-0 flex items-center">
                <Icon class="w-5 h-5 mr-3" icon="material-symbols:lock" />
                <span class="">Security</span>
            </div>
            <div class="mt-5 container py-10 rounded-md bg-fg-light dark:bg-fg-dark">
                <mdText :text="'Password Change'" />
                <div class="grid gap-4 grid-cols-1 sm:grid-cols-2">
                    <labelText :id="'oldPassword'" :type="inputType.oldPassword" :label="'Old Password'"
                        :value="oldPassword" :placeholder="''" :error="errors.oldPassword"
                        :postfix-icon="inputType.oldPassword === 'password' ? 'fluent:eye-show-16-regular' : 'fluent:eye-hide-20-regular'"
                        @input-field-value-changed="passwordValuechanged" @iconClick="changeinputTypeIcon" />
                </div>
                <div class="grid gap-4 grid-cols-1 sm:grid-cols-2">
                    <labelText :id="'newPassword'" :type="inputType.newPassword" :label="'New Password'"
                        :value="newPassword" :placeholder="''" :error="errors.newPassword"
                        :postfix-icon="inputType.newPassword === 'password' ? 'fluent:eye-show-16-regular' : 'fluent:eye-hide-20-regular'"
                        @input-field-value-changed="newPasswordValuechanged" @iconClick="changeinputTypeIcon" />
                    <labelText :id="'confirmPassword'" :type="inputType.confirmPassword" :label="'Password Confirm'"
                        :value="confirmPassword" :placeholder="''" :error="errors.confirmPassword"
                        :postfix-icon="inputType.confirmPassword === 'password' ? 'fluent:eye-show-16-regular' : 'fluent:eye-hide-20-regular'"
                        @input-field-value-changed="passwordConfirmValuechanged" @iconClick="changeinputTypeIcon" />
                </div>
                <btnText :button-text="'Save Changes'" @click-event="changePasswordTrigger()" />
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import { Icon } from '@iconify/vue';
import store from '@/store/index';
import mdText from '@/components/common/titles/md-text.vue';
import labelText from '@/components/common/label-input-types/label-text.vue';
import { swal } from '@/utils/helper';
import { changePassword } from '@/services/api/staff';
import { useNotification } from "@kyvg/vue3-notification";
import btnText from '@/components/common/buttons/btn-text.vue';

const role = ref(store.getters.role);
const { notify } = useNotification();

const isLoading = ref(false);
let oldPassword = ref('');
let newPassword = ref('');
let confirmPassword = ref('');
let errors = ref({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
});
let inputType = ref({
    oldPassword: 'password',
    newPassword: 'password',
    confirmPassword: 'password',
});

const passwordValuechanged = (val) => {
    oldPassword.value = val;
    errors.value.oldPassword = '';
};

const newPasswordValuechanged = (val) => {
    newPassword.value = val;
    errors.value.newPassword = '';
};

const passwordConfirmValuechanged = (val) => {
    confirmPassword.value = val;
    errors.value.confirmPassword = '';
};

//change show hide icon related to input type
const changeinputTypeIcon = (type) => {

    if (inputType.value[type] === 'password') {
        inputType.value[type] = 'text';
    } else {
        inputType.value[type] = 'password';
    }
};

//input fields password reset validation
const passwordValidation = () => {
    if (oldPassword.value === '') {
        errors.value.oldPassword = 'Old password is required!';
    } else {
        errors.value.oldPassword = '';
    }

    if (newPassword.value === '') {
        errors.value.newPassword = 'New password is required!';
    } else if (newPassword.value.length <= 7) {
        errors.value.newPassword = 'Password should contained 8 or more characters!';
    } else {
        errors.value.newPassword = '';
    }

    if (confirmPassword.value === '') {
        errors.value.confirmPassword = 'Password confitmation is required!';
    } else if (newPassword.value !== confirmPassword.value) {
        errors.value.confirmPassword = 'Password confitmation did not matched with the new password!';
    } else {
        errors.value.confirmPassword = '';
    }
};

const changePasswordTrigger = async () => {
    passwordValidation();

    if (errors.value.oldPassword === '' && errors.value.newPassword === '' && errors.value.confirmPassword === '') {
        swal('Password Change Confirmation!', 'Are you sure you want to change the password?', 'warning', true, 'Confirm', () => { }, async () => {
            isLoading.value = true;

            const postData = {
                oldPassword: oldPassword.value,
                newPassword: newPassword.value,
                newPasswordConfirm: confirmPassword.value,
            };

            try {
                const response = await changePassword(postData);
                notify({
                    type: "success",
                    title: response.data.title,
                    text: response.data.message,
                });
                oldPassword.value = '';
                newPassword.value = '';
                confirmPassword.value = '';
            } catch (error) {
                notify({
                    type: "error",
                    title: error.response.data.title,
                    text: error.response.data.message,
                });
            }
            isLoading.value = false;
        });
    }
};

</script>