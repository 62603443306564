import axios from "axios";
import { CONSTANTS } from '@/utils/constants';
import store from '@/store/index';

// get reviws
export const getAllReviews = async (type) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/reviews/get-all-reviews/${type}`, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};

// get reviws
export const changeReviewVisibility = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/reviews/change-visibility`, data, {
        headers: {
            Authorization: `Bearer ${store.getters.token}`
        }
    });
    return response;
};