<template>
    <div @click="addStocksPopupClose()"
        class="flex justify-center items-center fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm bg-[#00000038] z-10">
        <loading :loading="isLoading" />
        <notifications />
        <div @click.stop
            class="max-w-[700px] max-h-[80vh] w-full p-5 flex flex-col justify-start rounded-md bg-bg-light dark:bg-bg-dark">
            <div class="flex justify-end">
                <button @click="addStocksPopupClose()">
                    <Icon class="w-5 h-5" icon="ic:baseline-close" />
                </button>
            </div>
            <div>
                <labelSelect :id="'supplier'" :label="'Supplier'" :value="selectedSupplier" :options="suppliers"
                    :extra-option="{ name: 'Select an option', value: '', isVisible: true }" :error="errors.supplier"
                    @input-field-value-changed="supplierValuechanged" />
                <div class="mb-5 relative">
                    <p class="mb-1">Item</p>
                    <input v-model="item" @input="loadSearchedItems()" type="text" name="item" id="item"
                        class="w-full h-11 px-3 rounded-md border bg-fg-light dark:bg-fg-dark"
                        :class="errors.item !== '' ? 'border-red-600 dark:border-red-600' : 'dark:border-gray-600'"
                        placeholder="Type the item name to search">
                    <p class="text-sm text-red-600">{{ errors.item }}</p>
                    <div v-if="searchedItemsDropdown"
                        class="max-h-[200px] px-5 absolute top-[80px] right-0 left-0 overflow-y-auto bg-fg-light dark:bg-fg-dark">
                        <p v-if="items.length === 0" class="py-10 text-center">No item(s) found</p>
                        <div v-for="(item, index) in items" :key="index" @click="itemSelect(index)"
                            class="flex items-center gap-5  border-b border-bg-light dark:border-bg-dark duration-300 hover:opacity-70 cursor-pointer">
                            <div class="w-10 h-10  grid place-items-center">
                                <img class="max-w-full max-h-full" :src="item.img_path_1" alt="">
                            </div>
                            <p>{{ item.name }}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p>New Stock</p>
                    <div class="flex gap-3">
                        <labelText :id="'price'" :type="'text'" :label="''" :value="size"
                            :placeholder="'Size (10 / 12 / XL)'" :error="errors.size"
                            @input-field-value-changed="sizeValuechanged" />
                        <labelText :id="'price'" :type="'text'" :label="''" :value="stock" :placeholder="'Stock (5)'"
                            :error="errors.stock" @input-field-value-changed="stockValuechanged" />
                        <button @click="sizeStockAdd()"
                            class="w-[44px] h-[44px] mt-1 rounded-md flex justify-center items-center flex-shrink-0 transition duration-300 ease-in-out bg-primary hover:bg-secondary">
                            <Icon class="w-5 h-5 text-white" icon="ic:baseline-plus" />
                        </button>
                    </div>
                    <p class="text-sm text-red-600">{{ errors.sizesStocks }}</p>
                    <div class="flex flex-wrap gap-3">
                        <div v-for="(item, index) in sizesStocks" :key="index"
                            class="p-3 rounded-md flex items-center bg-fg-light dark:bg-fg-dark relative">
                            <button @click="sizeStockRemove(index)"
                                class="w-4 h-4 bg-[#c1c1c1] dark:bg-[#4c4c4c] rounded-full flex justify-center items-center absolute top-[-5px] right-[-5px]">
                                <Icon class="w-3 h-3 text-white" icon="ri:close-line" />
                            </button>
                            <div class="w-7 h-7 border flex justify-center items-center rounded-full">
                                <p class="text-[12px]">{{ item.size }}</p>
                            </div>
                            <p class="mx-2">-</p>
                            <p>{{ item.stock }}</p>
                        </div>
                    </div>
                </div>
                <div class="mb-5 flex justify-center">
                    <btnText :buttonText="'Submit'" @clickEvent="submit()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineEmits, onMounted } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import { Icon } from '@iconify/vue';
import { useNotification } from "@kyvg/vue3-notification";
import labelText from '@/components/common/label-input-types/label-text.vue';
import labelSelect from '@/components/common/label-input-types/label-select.vue';
import btnText from '@/components/common/buttons/btn-text.vue';
import { getAllSuppliers, getSearchedItems, addNewStock } from '@/services/api/suppliers';

const { notify } = useNotification();

const isLoading = ref(false);
const suppliers = ref([]);
const selectedSupplier = ref('');
const items = ref([]);
const searchedItemsDropdown = ref(false);
const item = ref('');
let selectedItemId = '';
let timeoutId = null;
const size = ref('');
const stock = ref('');
const sizesStocks = ref([]);

const errors = ref({
    supplier: '',
    item: '',
    size: '',
    stock: '',
    sizesStocks: ''
});

onMounted(() => {
    loadSuppliers()
});

const supplierValuechanged = (val) => {
    selectedSupplier.value = val;
    errors.value.supplier = '';
};

const sizeValuechanged = (val) => {
    size.value = val;
    errors.value.size = '';
};

const stockValuechanged = (val) => {
    stock.value = val;
    errors.value.stock = '';
};

const loadSuppliers = async () => {
    try {
        const response = await getAllSuppliers();
        suppliers.value = response.data;
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
};

const loadSearchedItems = async () => {
    if (item.value !== '') {
        errors.value.item = '';
        clearTimeout(timeoutId);
        timeoutId = setTimeout(async () => {
            isLoading.value = true;
            searchedItemsDropdown.value = true;
            try {
                const response = await getSearchedItems(item.value);
                items.value = response.data;
                console.log(items.value);
            } catch (error) {
                notify({
                    type: "error",
                    title: error.response.data.title,
                    text: error.response.data.message,
                });
            }
            isLoading.value = false;
        }, 1000);
    }
};

const itemSelect = (index) => {
    searchedItemsDropdown.value = false;
    item.value = items.value[index].name;
    selectedItemId = items.value[index].id;
};

const sizeStockRemove = (index) => {
    sizesStocks.value.splice(index, 1);
};

const sizeStockAdd = () => {
    if (size.value === '') {
        errors.value.size = 'Size is required!';
    } else if (sizesStocks.value.some(item => item.size === size.value)) {
        errors.value.size = 'This type is already added!';
    } else {
        errors.value.size = '';
    }

    if (stock.value === '') {
        errors.value.stock = 'Stock is required!';
    } else if (isNaN(stock.value)) {
        errors.value.stock = 'Invalid stock type!';
    } else {
        errors.value.stock = '';
    }

    if (errors.value.size === '' && errors.value.stock === '') {
        if (isNaN(size.value)) {
            sizesStocks.value.push({ size: (size.value).toUpperCase(), stock: parseInt(stock.value) });
        } else {
            sizesStocks.value.push({ size: size.value, stock: parseInt(stock.value) });
        }
        size.value = '';
        stock.value = '';
        errors.value.sizesStocks = '';
    }
};

const submit = async () => {
    if (selectedSupplier.value === '') {
        errors.value.supplier = 'Please select the supplier!';
    } else {
        errors.value.supplier = '';
    }

    if (selectedItemId === '') {
        errors.value.item = 'Please select the item!';
    } else {
        errors.value.item = '';
    }

    if (sizesStocks.value.length === 0) {
        errors.value.sizesStocks = 'New stock is required!';
    } else {
        errors.value.sizesStocks = '';
    }

    if (!errors.value.supplier && !errors.value.item && !errors.value.sizesStocks) {
        isLoading.value = true;
        try {
            const postData = {
                supplierId: selectedSupplier.value,
                itemId: selectedItemId,
                newSizesStock: sizesStocks.value,
            };

            const response = await addNewStock(postData);
            addStocksPopupClose(response.data);
        } catch (error) {
            notify({
                type: "error",
                title: error.response.data.title,
                text: error.response.data.message,
            });
        }
        isLoading.value = false;
    }
};

const emits = defineEmits(['addStocksPopupClose']);

const addStocksPopupClose = (response) => {
    emits('addStocksPopupClose', response);
};
</script>