/* eslint-disable */
import { createWebHistory, createRouter } from 'vue-router';
import store from '@/store/index';
import Dashboard from '@/views/dashboard-scr.vue';
import AddItem from '@/views/items/add-item-scr.vue';
import ManageItems from '@/views/items/manage-items-scr.vue';
import BannerItems from '@/views/items/banner-items-scr.vue';
import RegisteredUsers from '@/views/users/registered-users-scr.vue';
import NewsletterSubscribers from '@/views/users/newsletter-subscribers-scr.vue';
import Customize from '@/views/customize-scr.vue';
import Orders from '@/views/orders-scr.vue';
import Suppliers from '@/views/supply/suppliers-scr.vue';
import CurrentStock from '@/views/supply/current-stock-scr.vue';
import StockHistory from '@/views/supply/stock-history-scr.vue';
import Reviews from '@/views/reviews-scr.vue';
import Staff from '@/views/staff-scr.vue';
import Settings from '@/views/settings-scr.vue';
import Login from '@/views/login-scr.vue';

const routes = [
    {
        name: 'Dashboard',
        path: '/',
        component: Dashboard,
        meta: {
            title: 'Dashboard',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Add Item',
        path: '/add-item',
        component: AddItem,
        meta: {
            title: 'Add Items',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Manage Items',
        path: '/manage-items',
        component: ManageItems,
        meta: {
            title: 'Manage Items',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Banner Items',
        path: '/banner-items',
        component: BannerItems,
        meta: {
            title: 'Banner Items',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Registered Users',
        path: '/registered-users',
        component: RegisteredUsers,
        meta: {
            title: 'Registered Users',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Newsletter Subscribers',
        path: '/newsletter-subscribers',
        component: NewsletterSubscribers,
        meta: {
            title: 'Newsletter Subscribers',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Customize',
        path: '/customize',
        component: Customize,
        meta: {
            title: 'Customize',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Orders',
        path: '/orders',
        component: Orders,
        meta: {
            title: 'Orders',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Suppliers',
        path: '/suppliers',
        component: Suppliers,
        meta: {
            title: 'Suppliers',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager']
        }
    },

    {
        name: 'Stock History',
        path: '/stock-history',
        component: StockHistory,
        meta: {
            title: 'Stock History',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager']
        }
    },

    {
        name: 'Current Stock',
        path: '/current-stock',
        component: CurrentStock,
        meta: {
            title: 'Current Stock',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager']
        }
    },

    {
        name: 'Reviews',
        path: '/reviews',
        component: Reviews,
        meta: {
            title: 'Reviews',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Staff',
        path: '/staff',
        component: Staff,
        meta: {
            title: 'Staff',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Settings',
        path: '/settings',
        component: Settings,
        meta: {
            title: 'Settings',
            requiresAuth: true,
            allowedRoles: ['admin', 'manager', 'staff']
        }
    },

    {
        name: 'Login',
        path: '/login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    const isLoggedIn = store.getters.isLoggedIn;
    const role = store.getters.role;
  
    if (to.matched.length === 0) {
        next('/');
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isLoggedIn) {
            next('/login');
        } else {
            if (role === null) {
                await store.dispatch('fetchRole');
            }

            if (to.matched.some(record => record.meta.allowedRoles.includes(role))) {
                next();
            } else {
                next('/');
            }
        }
    } else {
        if (isLoggedIn && to.path === '/login') {
            next('/');
        } else if (!isLoggedIn && to.path !== '/login') {
            next('/login');
        } else {
            next();
        }
    }
  });

export default router;