<template>
    <div @click="addSupplierPopupClose()"
        class="flex justify-center items-center fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm bg-[#00000038] z-10">
        <loading :loading="isLoading" />
        <notifications />
        <div @click.stop
            class="max-w-[700px] max-h-[80vh] w-full p-5 flex flex-col justify-start rounded-md bg-bg-light dark:bg-bg-dark">
            <div class="flex justify-end">
                <button @click="addSupplierPopupClose()">
                    <Icon class="w-5 h-5" icon="ic:baseline-close" />
                </button>
            </div>
            <div>
                <labelText :id="'name'" :type="'text'" :label="'Name'" :value="name" :placeholder="'Peter Griffin'"
                    :error="errors.name" @input-field-value-changed="nameValuechanged" />
                <labelText :id="'email'" :type="'text'" :label="'Email'" :value="email" :placeholder="'peter@gmail.com'"
                    :error="errors.email" @input-field-value-changed="emailValuechanged" />
                <labelText :id="'phone'" :type="'text'" :label="'Phone'" :value="phone" :placeholder="'+94 ** *** **** '"
                    :error="errors.phone" @input-field-value-changed="phoneValuechanged" />
                <div class="mb-5 flex justify-center">
                    <btnText :buttonText="'Submit'" @clickEvent="submit()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineEmits } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import { Icon } from '@iconify/vue';
import { useNotification } from "@kyvg/vue3-notification";
import labelText from '@/components/common/label-input-types/label-text.vue';
import btnText from '@/components/common/buttons/btn-text.vue';
import { addNewSupplier } from '@/services/api/suppliers';

const { notify } = useNotification();

const isLoading = ref(false);
const name = ref('');
const email = ref('');
const phone = ref('');

const errors = ref({
    name: '',
    email: '',
    phone: '',
});

const nameValuechanged = (val) => {
    name.value = val;
    errors.value.name = '';
};

const emailValuechanged = (val) => {
    email.value = val;
    errors.value.email = '';
};

const phoneValuechanged = (val) => {
    phone.value = val;
    errors.value.phone = '';
};

const submit = async () => {
    if (name.value === '') {
        errors.value.name = 'Name is required!';
    } else {
        errors.value.name = '';
    }

    if (email.value === '') {
        errors.value.email = 'Email is Required!';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
        errors.value.email = 'Invalid Email Format!';
    } else {
        errors.value.email = '';
    }

    if (phone.value === '') {
        errors.value.phone = 'Phone number is required!';
    } else {
        errors.value.phone = '';
    }

    if (!errors.value.name && !errors.value.email && !errors.value.phone) {
        try {
            const postData = {
                name: name.value,
                email: email.value,
                phone: phone.value
            };

            const response = await addNewSupplier(postData);
            addSupplierPopupClose(response.data);
        } catch (error) {
            notify({
                type: "error",
                title: error.response.data.title,
                text: error.response.data.message,
            });
        }
    }
};

const emits = defineEmits(['addSupplierPopupClose']);

const addSupplierPopupClose = (response) => {
    emits('addSupplierPopupClose', response);
};
</script>