<template>
    <button @click="buttonClick()" class="mt-5 py-3 px-10 rounded-md font-bold flex justify-center items-center gap-2 text-white bg-primary transition duration-300 ease-in-out hover:bg-secondary">
        <Icon v-if="props.icon" class="w-6 h-6" :icon="props.icon" />
        <p>{{ props.buttonText }}</p>
    </button>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { Icon } from '@iconify/vue';

const props = defineProps({
    buttonText: {
        type: String,
        default: 'Submit',
    },

    icon: {
        type: String,
        default: '',
    }
});

const emits = defineEmits(['clickEvent']);

const buttonClick = () => {
    emits('clickEvent');
};
</script>