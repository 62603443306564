<template>
    <div @click="editStockPopupClose()"
        class="flex justify-center items-center fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm bg-[#00000038] z-10">
        <loading :loading="isLoading" />
        <notifications />
        <div @click.stop
            class="max-w-[700px] max-h-[80vh] w-full p-5 flex flex-col justify-start rounded-md bg-bg-light dark:bg-bg-dark">
            <div class="flex justify-end">
                <button @click="editStockPopupClose()">
                    <Icon class="w-5 h-5" icon="ic:baseline-close" />
                </button>
            </div>
            <div>
                <!-- form -->
                <div>
                    <p v-if="currentStock.length > 0">Current Stock</p>
                    <div class="mt-5 flex flex-wrap gap-3">
                        <div v-for="(item, index) in currentStock" :key="index"
                            class="p-3 rounded-md flex items-center bg-fg-light dark:bg-fg-dark relative">
                            <button @click="sizeStockRemoveAll(index)"
                                class="w-4 h-4 bg-[#c1c1c1] dark:bg-[#4c4c4c] rounded-full flex justify-center items-center absolute top-[-5px] left-[-5px]">
                                <Icon class="w-3 h-3 text-white" icon="ri:close-line" />
                            </button>
                            <div class="w-7 h-7 border flex justify-center items-center rounded-full">
                                <p class="text-[12px]">{{ item.size }}</p>
                            </div>
                            <p class="mx-2">-</p>
                            <div class="flex flex-col items-center">
                                <div class="h-3"></div>
                                <!-- <Icon class="w-3 h-3 rotate-[-90deg] text-white" icon="material-symbols:play-arrow" /> -->
                                <p>{{ item.stock }}</p>
                                <Icon @click="sizeStockRemoveSingle(index)"
                                    class="w-3 h-3 rotate-90 cursor-pointer text-white"
                                    icon="material-symbols:play-arrow" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="removedStock.length > 0 && currentStock.length > 0"
                    class="h-[1px] my-5 bg-[#dfdfdf] dark:bg-[#717171]"></div>
                <div v-if="removedStock.length > 0">
                    <p class="text-red-600">Removed Stock</p>
                    <div class="mt-5 flex flex-wrap gap-3">
                        <div v-for="(item, index) in removedStock" :key="index"
                            class="p-3 rounded-md flex items-center bg-[#ff000046] relative">
                            <button @click="sizeStockAddAll(index)"
                                class="w-4 h-4 bg-[#c1c1c1] dark:bg-[#4c4c4c] rounded-full flex justify-center items-center absolute top-[-5px] left-[-5px]">
                                <Icon class="w-3 h-3 text-white" icon="ri:close-line" />
                            </button>
                            <div class="w-7 h-7 border flex justify-center items-center rounded-full">
                                <p class="text-[12px] text-red-600">{{ item.size }}</p>
                            </div>
                            <p class="mx-2 text-red-600">-</p>
                            <div class="flex flex-col items-center">
                                <Icon @click="sizeStockAddSingle(index)"
                                    class="w-3 h-3 rotate-[-90deg] cursor-pointer text-white"
                                    icon="material-symbols:play-arrow" />
                                <p class="text-red-600">{{ item.stock }}</p>
                                <!-- <Icon class="w-3 h-3 rotate-90 text-white" icon="material-symbols:play-arrow" /> -->
                                <div class="h-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-5 flex justify-center">
                    <btnText :class="removedStock.length > 0 ? 'opacity-100 cursor-pointer' : 'opacity-30 cursor-not-allowed'" :buttonText="'Submit'" @clickEvent="submit()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, defineEmits } from 'vue';
import loading from '@/components/common/loading-overlay-com.vue';
import { Icon } from '@iconify/vue';
import { useNotification } from "@kyvg/vue3-notification";
import labelText from '@/components/common/label-input-types/label-text.vue';
import labelSelect from '@/components/common/label-input-types/label-select.vue';
import btnText from '@/components/common/buttons/btn-text.vue';
import { removeStock } from '@/services/api/suppliers';

const { notify } = useNotification();

const props = defineProps({
    stock: {
        type: Object,
    },

    supplierId: {
        type: String,
    },

    itemId: {
        type: String,
    }
});

const isLoading = ref(false);
const currentStock = ref(JSON.parse(JSON.stringify(props.stock)));
const removedStock = ref([]);

const sizeStockRemoveAll = (index) => {
    const alreadyRemovedIndex = removedStock.value.findIndex((item) => item.size === currentStock.value[index].size);

    if (alreadyRemovedIndex === -1) {
        removedStock.value.push({ size: currentStock.value[index].size, stock: currentStock.value[index].stock });
    } else {
        removedStock.value[alreadyRemovedIndex].stock += currentStock.value[index].stock;
    }
    currentStock.value.splice(index, 1);
};

const sizeStockRemoveSingle = (index) => {
    const alreadyRemovedIndex = removedStock.value.findIndex((item) => item.size === currentStock.value[index].size);
    if (alreadyRemovedIndex !== -1) {
        removedStock.value[alreadyRemovedIndex].stock += 1;
    } else {
        removedStock.value.push({ size: currentStock.value[index].size, stock: 1 });
    }

    currentStock.value[index].stock -= 1;

    if (currentStock.value[index].stock === 0) {
        currentStock.value.splice(index, 1);
    }
};

const sizeStockAddAll = (index) => {
    const currentStockIndex = currentStock.value.findIndex((item) => item.size === removedStock.value[index].size);

    if (currentStockIndex === -1) {
        currentStock.value.push({ size: removedStock.value[index].size, stock: removedStock.value[index].stock });
    } else {
        currentStock.value[currentStockIndex].stock += removedStock.value[index].stock;
    }
    removedStock.value.splice(index, 1);
};

const sizeStockAddSingle = (index) => {
    const currentStockIndex = currentStock.value.findIndex((item) => item.size === removedStock.value[index].size);
    if (currentStockIndex !== -1) {
        currentStock.value[currentStockIndex].stock += 1;
    } else {
        currentStock.value.push({ size: removedStock.value[index].size, stock: 1 });
    }

    removedStock.value[index].stock -= 1;

    if (removedStock.value[index].stock === 0) {
        removedStock.value.splice(index, 1);
    }
};

const submit = async () => {
    isLoading.value = true;
    try {
        if (removedStock.value.length > 0) {
            const postData = {
                supplierId: props.supplierId,
                itemId: props.itemId,
                removedSizesStocks: removedStock.value
            }

            const response = await removeStock(postData);
            removedStock.value = [];
            editStockPopupClose(response.data);
        }
    } catch (error) {
        notify({
            type: "error",
            title: error.response.data.title,
            text: error.response.data.message,
        });
    }
    isLoading.value = false;
};

const emits = defineEmits(['editStockPopupClose']);

const editStockPopupClose = (response) => {
    emits('editStockPopupClose', response);
};
</script>